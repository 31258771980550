.cardTwo{
    width: 80%;
    height: 400px;
    max-height: 100%;
    margin: 3rem auto;

    display: flex;
    
    justify-content: space-between;
}

.cardTwo_content{
    flex: .4;
}


.cardTwo_content-header,.cardTwo_content-parragraph{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
}
.cardTwo_content-header h3{
    font-size: 32px;
    font-weight: 500;
    line-height: 39px;
}
.cardTwo_content-header  span{
    height: 60px;
    width: 60px;
    display: block;
}

.cardTwo_content-parragraph{
   margin-top: 1.5rem;
}

.cardTwo_img img,.cardTwo_content-header span img,.cardTwo_content-parragraph span img{
    width: 100%;
    height: 100%;
}


.cardTwo_content-parragraph span{
    width: 30px;
    height: 20px;
    display: block;
}


.cardTwo_content-parragraph h4{
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 26.82px;
}


.rowRev{
    flex-direction: row-reverse;
}

/* ==========Responsive =========== */

@media only screen and (max-width:1600px) {
    .space_1{
        margin-bottom: 3rem;
    }
}

@media only screen and (max-width:1600px) and (min-width:1025px){
    .cardTwo{
        width: 80%;
        height: fit-content;
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
        gap: 3rem;
    }
    .cardTwo_content-parragraph{
        gap: 1rem;
        align-items: flex-start;
        margin-top: 1rem;
    }   

    .cardTwo_content-parragraph span{
        width: 1.5rem;
        height: 1.4rem;
        transform: rotate(20deg);
        margin-top: .3rem;

    }
}
@media only screen and (max-width:1024px) and (min-width:769px){
    .cardTwo{
        width: 100%;
        height: fit-content;
        padding: 3rem;
        
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
        gap: 3rem;
    }
    .cardTwo_content-parragraph{
        gap: 1rem;
        align-items: flex-start;
        margin-top: 1rem;
    }   

    .cardTwo_content-parragraph span{
        width: 1.5rem;
        height: 1.4rem;
        transform: rotate(20deg);
        margin-top: .3rem;

    }
}
@media only screen and (max-width:769px){
    .cardTwo{
        width: 100%;
        height: fit-content;
        padding: 1rem;
        
        flex-direction: column;
        gap: 2rem;
    }

    .cardTwo_content-parragraph h4{
        font-size: 1.1rem;
    }

    .cardTwo_content-parragraph{
        gap: 1rem;
        align-items: flex-start;
        margin-top: 1rem;
    }   


    .cardTwo_content-header span{
        width:fit-content;
        height: 40px;
    }

    .cardTwo_content-header h3{
        font-size: 1.8rem;
        margin-left:-1rem;
    }

    .cardTwo_content-parragraph span{
       display: none;
    }
    .cardTwo_content-parragraph{
        margin-top: .5rem;
    }
}

.users_container{
    position: relative;
}
.user_container_add button{
    font-size: 1.5rem;
    padding: .4rem 1rem;
    font-weight: 500;
    margin-left: auto;
    border: 0;
    border-radius: .4rem;
    background-color: var(--primary-color-2);
    cursor: pointer;
    transition: all .2s ease-in;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: .7rem;
}
.user_container_add button:hover{
    background-color: var(--primary-color);
}

.add_user_icon{
    font-size: 2rem;
    color: aliceblue;
}

.user_header,.user_content{
    margin-top: .3rem;
    display: flex;
    align-items: center;
    gap: .5rem;  
}
.user_header h4{
    flex: .25;
    text-transform: capitalize;
    font-size: 1.5rem;
    font-weight: 500;
}
.user_header h4:nth-child(1){
    padding-left: 1rem;
}

.user_content_wrap{
    height: 72vh;
    overflow-y: auto;
}

.user_content{
    border-radius: .7rem;
    border: 1px solid lightgray;
}

.user_content:hover{
    background-color: rgba(105, 241, 178, 0.1);
    box-shadow: 0 0 .3rem lightgray ;
}

.user_content p{
    flex: .25;
    text-transform: capitalize;
    font-size: 1.3rem;
}

.user_content p:nth-child(1){
    display: flex;
    align-items: center;
    gap: .5rem;
    padding: .5rem 0 .5rem 1rem;
}
.user_content p:nth-child(1) img{
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 50%;
}
.user_content p:nth-child(2){
    text-transform: lowercase;
}

.user_content p:nth-child(3){
    display: flex;
    align-items: center;
    gap: .5rem;
}

.user_content p:nth-child(3) span{
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
}

.actives{
    background-color: green;
}
.deactive{
    background-color: red;

}

.user_content p:nth-child(3) button{
    border: 0;
    font-size: 1.2rem;
    border-radius: .4rem;
    padding: .4rem 1rem;
    margin-left: .5rem;
    cursor: pointer;
    color: var(--text-white);
    font-weight: 600;
    text-transform: capitalize;
    /* display: none; */
    /* pointer-events: none; */
}
.user_content p:nth-child(3):hover button{
    display: block;
    pointer-events: auto;
}
.user_content p:nth-child(4){
    display: flex;
    align-items: center;
    gap: 1rem;
}

.user_content p:nth-child(4) button{
    font-size: 1.2rem;
    text-transform: capitalize;
    padding: .5rem 1rem;
    border: 0;
    cursor: pointer;
    border-radius: .3rem;
    color: var(--text-white);

    display: flex;
    align-items: center;
    gap: .3rem;
    
}


.user_content p:nth-child(4) button:nth-child(3){
    background-color:rgba(137, 102, 243, 0.681);
}
.user_content p:nth-child(4) button:nth-child(3):hover{
    background-color:rgb(89, 33, 255) ;
}
.user_content p:nth-child(4) button:nth-child(2){
    background-color:rgba(35, 149, 248, 0.578) ;
}
.user_content p:nth-child(4) button:nth-child(2):hover{
    background-color:rgb(0, 170, 255) ;
}
.user_content p:nth-child(4) button:nth-child(1){
    background-color:rgba(243, 102, 173, 0.713) ;
}
.user_content p:nth-child(4) button:nth-child(1):hover{
    background-color:rgb(255, 9, 9) ;
}

.user_icon_delete{
    color: var(--text-black);
}

/* Add new User Form css ======= */
.user_add_form_container,.user_delete_model_wrap{
    background-color: rgba(39, 60, 117,.95);
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: fit-content;
    padding-bottom: 2rem;
}
.user_add_header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem 3rem;
    margin-top: 1rem;
}
.user_add_header h3{
    font-size: 2rem;
    font-weight: 500;
    color: var(--text-white);
}

.add_user_header_icon{
    font-size:3rem;
    margin-right: 1rem;
    padding: .3rem;
    border-radius: .4rem;
    cursor: pointer;
    color: aliceblue;
    background-color: rgba(255, 0, 0, .4);

}
.add_user_header_icon:hover{
    background-color: rgba(255, 0, 0, 1);

}

.user_add_form_container form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    min-width: 60%;
    border: 1px solid lightgray;
    color: var(--text-white);
    padding: 1rem 10%;
    border-radius: .7rem;
}
.user_add_form_container form > div {
    display: flex;
    flex-direction: column;
    gap: .4rem;
}
.user_add_form_container form label {
    font-size: 1.2rem;
}
.user_add_form_container form > div input {
    height: 52px;
    border: 1px solid lightgray;
    border-radius: .5rem;
    font-size: 1.5rem;
    padding: 0 1rem;
    background-color: var(--text-white);
    outline: 0;
}

.user_add_form_container form > div input:focus{
    box-shadow: 0 0 .3rem lightgray;
}

.user_add_form_container form > div p {
    color: red;
}

.picture_avater >input {
    height: 100% !important;
    padding: .5rem 1rem !important;
}


.user_add_form_container form>button {
    font-size: 1.5rem;
    margin: 1rem 10%;
    padding: .4rem;
    border-radius: .4rem;
    border: 0;
    cursor: pointer;
    background-color: var(--primary-color);
    color: var(--text-white);
}

.user_add_form_container form>button:hover {
    box-shadow: 0 0 .3rem var(--text-white);
    background-color: var(--primary-color-2);
}

.user_roll_set {
    align-self: flex-start;
    width: 20%;
    font-size: 1.3rem;
    font-weight: 500;
    padding: .2rem;
    border-radius: .2rem;
}

.user_add_form_container form > div {
    display: flex;
    flex-direction: column;
    gap: .4rem;
}
.parmation_select h4 {
    font-weight: 400;
    margin-bottom: .4rem;
    font-size: 1.1rem;
}

.parmation_select > span {
    display: flex;
    justify-content: space-between;
}
.parmation_select > span>label input {
    height: 1.5rem;
    width: 1.5rem;
}

.parmation_select > span>label {
    display: flex;
    align-items: center;
    gap: .4rem;
    text-transform: capitalize;
}


.parmation_select {
    border: 1px solid #e4e4e4;
    padding: .5rem;
    border-radius: .4rem;
}

.picture_avater input {
    border: 1px solid;
    background: transparent !important;
}

/* Deleted Model Css  */
.user_delete_model_wrap{
    justify-content: center;
    background:rgba(87, 101, 116,.8) ;
}
.user_delete_model{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 10rem;
    width: 30rem;
    background-color:rgba(84, 160, 255,1.0);
    box-shadow:0 0 .7rem rgba(0, 0, 255,0.5);
}

.user_delete_model button{
    font-size: 2rem; 
    padding: .4rem 1rem;
    border: 0;
    border-radius: .4rem;
    cursor: pointer;
}

.user_delete_model button:nth-child(2){
    background-color: var(--primary-color-2);
    color: var(--text-white);
}

.user_delete_model button:nth-child(2):hover{
    background-color: red;
}



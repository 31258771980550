.not-found-wrap{
    width: 100%;
    height: 100vh;
    max-height: 100%;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;

    background: url('../../../public/assets/pageNotFound-1.jpg');
    background-size: cover;
}

.wrap1{
    flex: 1;
    overflow: hidden;
}

.wrap1 img{
    width: 100%;
    height: 100%;
}

.wrap2{
    flex: 1;
    text-align: center;
}
.wrap2 p {
    font-size: 2rem;
    margin-bottom: 2rem;
    /* color: var(--primary-color-2); */
    color: var(--secondary-color);

}
.wrap2 a {
    color: var(--text-white);
    font-size: 1rem;
    background: var(--secondary-color);
    padding: .5rem 2rem;
    border-radius: 1.5rem;
    transition: all .4s ease-in;
}

.wrap2 a:hover{
    background: var(--primary-color-2);
}
.bunner-slide {
  max-width:1920px;
  padding: 0 10%;
}
.bunner-slide .slide-image img {
  width: 100%;
  height: 520px;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}
.bunner-slide .slide-image {
  position: relative;
}
.overfly {
  border-radius: 25px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 22;
  background-color: #343434c0;
}
.overfly h2 {
  font-size: 3rem;
  font-weight: 700;
  color: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mySwiper {
  padding: 0.2rem !important;
}
.swiper-pagination-bullet {
  padding: 5px;
}

@media screen and (max-width: 1920px) and (min-width: 1301px) {
  .bunner-slide {
    width: 80%;
    padding: 0;
  }
}
@media screen and (max-width: 1279px) and (min-width: 1024px) {
  .bunner-slide .slide-image img {
    height: 480px;
  }
 
}

@media screen and (max-width: 1024px) and (min-width: 681px) {
}

@media screen and (max-width: 680px) {
}

@media screen and (max-width: 492px) {
}

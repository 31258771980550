.offer-heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.offer-heading h2{
    font-size: 28px;
    font-weight: 800;
    color: #262626;
}
.offer-heading h2 span{
    font-weight: normal;
}
.offer-heading p button{
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    height: 40px;
    min-width: 120px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background: linear-gradient(90deg,#f55912 0,#f76b1c),linear-gradient(180deg,#fff,#fff);
}
.offer-main > p{
    font-size: 14px;
    font-weight: 500;
    margin: 20px 0 12px;
}
@media only screen and (max-width: 1025px) and (min-width: 769px) {
  
  }
  @media only screen and (max-width: 769px) {
    .offer-heading{
        flex-direction: column;
    }
    .offer-heading h2{
        font-size: 22px;
        text-align: center;
    }
    .offer-heading p button{
        margin: 20px 0;
    }
  }
  
  @media only screen and (max-width: 470px) {
    
  }
.app_card_container {
  border: 1px solid lightgray;
  padding: 2rem;
  background: url('../../../../public/assets/appcared_bg.jpg');
  background-size: 100%;
  background-position: 50%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-radius: 1rem;
  box-shadow: 0 0 1px rgba(0, 255, 242, 0.801);
  display: flex;
  gap: 2rem;
}

.app_card_right {
  flex: 0.7;
  flex-direction: column;
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 1rem;
}

.app_card_right > h2 {
  font-size: 24px;
  font-weight: 500;
}

.app_card_right > button {
  border: 0;
  outline: 0;
  font-size: 20px;
  font-weight: 500;
  height: 45px;
  color: #2abfc4;
  background: transparent;
  text-decoration: underline;
  cursor: pointer;
  transition: all 0.3s ease;
}
.app_card_right > button:hover {
  color: tomato;
}

.app_card_left {
  flex: 0.3;
  display: flex;
  align-items: center;
  gap: 1rem;
}

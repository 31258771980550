.services-bunner{
    width: 500px;
}

.Services-slide img::after {
    content: "";
    width: 50px;
    height: 50px;
    bottom: 50px;
    background-color: blue;
}

/* Responsive Layouts */
@media (min-width: 640px) { 

}
@media (min-width: 768px) { 

}
@media (min-width: 1024px) {

 }
@media (min-width: 1280px) {

 }
@media (min-width: 1536px) { 
    .services-bunner{
        width: 600px;
    }
 }
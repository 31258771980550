.App {
  position: relative;
  scroll-behavior: smooth;
}
.App .scroll_to_top {
  border: 0;
  outline: 0;
  background: #f1634c;
  box-shadow: 0px 8px 20px rgba(52, 52, 52, 0.2);
  border: 1px solid #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  position: fixed;
  bottom: 2rem;
  right: 2rem;
  padding: 0.5rem 0.6rem;
  border-radius: 0.2rem;
  cursor: pointer;
  z-index: 1000;
}

.arrow_icon-scroll {
  color: #fff;
  font-size: 3rem;
}

/* Bubble animation btn========== */
.bubble-animate-btn {
  transition: all 0.3s ease-in-out;
}

.bubble-animate-btn:hover {
  transform: scale(1.2);
}
/* Loading wrap Css */
.loading-wrap {
  width: 80vw;
  max-width: 100%;
  margin: 0 auto;
}
.loading-wrap p {
  font-size: 1.5rem;
  font-weight: 500;
}

.loading-wrap img,
.loading-wrap p {
  margin: 0 auto;
}

@media only screen and (max-width: 768px) {
  .App .scroll_to_top {
    bottom: 1rem;
    right: 1rem;
    padding: 0.25rem 0.3rem;
  }
  .arrow_icon-scroll {
    font-size: 2rem;
  }
}

/* Page common Sizing  CSS====== */

.health_card {
  /* width: 85%; */
  max-width: 100%;
  margin: 0 auto;
}

@media only screen and (max-width: 1024px) {
  .health_card {
    width: 90%;
    max-width: 100%;
  }
}
@media only screen and (max-width: 1024px) {
  .health_card {
    width: 100%;
    max-width: 100%;
    padding: 0 10px;
  }
}

/* 24/7 Call Services=== */
/* .call_to {
  height: 100px;
  width: 400px;
  position: fixed;
  border: 1px solid red;
  bottom: 0;
  left: 0;
  background-color: grey;
}

.call_to-inner {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: red;
  justify-content: space-around;
}

.call_to-inner > span {
  display: block;
  height: 50px;
  width: 50px;
  background: #777;
}

.call_btn:link,
.call_btn:visited {
  text-transform: uppercase;
  text-decoration: none;
  padding: 15px 40px;
  display: inline-block;
  border-radius: 100px;
  transition: all 0.2s;
  position: absolute;
}

.call_btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.call_btn:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.call_btn-white {
  background-color: #fff;
  color: #777;
}

/* =========== */
/* .call_btn::after {
  content: '';
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s ;
}

.call_btn-white::after {
  background-color: #fff;
}

.call_btn:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.btn-animated {
  animation: moveInBottom 5s ease-out;
  animation-fill-mode: backwards;
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
} */

/* ==========oKEY ====== */

.hotline-phone-ring-container {
  height: 200px;
  width: fit-content;
  padding-left: 10rem;
  position: fixed;
  bottom: 1.5rem;
  right: 1rem;
  z-index: 999;
}

.call_ring {
  display: flex;
  align-items: center;
  position: relative;
}

.call_numbertn {
  height: 40px;
  width: fit-content;
  padding: 0 1rem 0 2.5rem;
  background: rgba(90, 208, 255, 0.664);
  display: grid;
  place-items: center;
  position: absolute;
  top: 68%;
  left: -50px;
  border: 1px solid #75eb50;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  box-shadow: 0 0 10px 0px rgba(172, 255, 47, 0.397);
  transition: all 1s ease;
  z-index: -1;
}
.call_numbertn > p {
  font-size: 18px;
  font-weight: 500;
  color: rgb(0, 0, 0);
}

.call_coccoc-alo-ph-circle {
  width: 130px;
  height: 130px;

  top: 20px;
  left: 20px;
  position: absolute;
  background-color: transparent;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid rgba(30, 30, 30, 0.4);
  opacity: 0.1;
  -webkit-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
  -moz-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
  -ms-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
  -o-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
  animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.call_coccoc-alo-phone {
  background-color: transparent;
  width: 140px;
  height: 140px;
  cursor: pointer;
  z-index: 200000 !important;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -webkit-transition: visibility 0.5s;
  -moz-transition: visibility 0.5s;
  -o-transition: visibility 0.5s;
  transition: visibility 0.5s;
  right: 150px;
  top: 30px;
  position: relative;
  z-index: 100;
}

.call_coccoc-alo-phone.call_coccoc-alo-green .call_coccoc-alo-ph-circle-fill {
  background-color: rgba(0, 175, 242, 0.5);
  opacity: 0.75 !important;
}

.call_coccoc-alo-ph-circle-fill {
  width: 90px;
  height: 90px;
  top: 40px;
  left: 40px;
  position: absolute;
  background-color: #000;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid transparent;
  opacity: 0.1;
  -webkit-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -moz-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -ms-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -o-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.call_coccoc-alo-ph-img-circle {
  width: 50px;
  height: 50px;
  top: 60px;
  left: 60px;
  position: absolute;
  background: rgba(30, 30, 30, 0.1)
    url(https://drive.google.com/uc?id=1V3N2b79QjDWetC_ss9wI3c-xpWDymn9R)
    no-repeat center center;
  background-size: 75%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid transparent;
  opacity: 0.7;
  -webkit-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
  -moz-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
  -ms-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
  -o-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
  animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
}

.call_coccoc-alo-phone.call_coccoc-alo-green .call_coccoc-alo-ph-img-circle {
  background-color: #00aff2;
}

.call_coccoc-alo-phone.call_coccoc-alo-green .call_coccoc-alo-ph-circle {
  border-color: #00aff2;
  opacity: 0.5;
}

.call_coccoc-alo-phone.call_coccoc-alo-green.call_coccoc-alo-hover
  .call_coccoc-alo-ph-circle,
.call_coccoc-alo-phone.call_coccoc-alo-green:hover .call_coccoc-alo-ph-circle {
  border-color: #75eb50;
  opacity: 0.5;
}

.call_coccoc-alo-phone.call_coccoc-alo-green.call_coccoc-alo-hover
  .call_coccoc-alo-ph-circle-fill,
.call_coccoc-alo-phone.call_coccoc-alo-green:hover
  .call_coccoc-alo-ph-circle-fill {
  background-color: rgba(117, 235, 80, 0.5);
  opacity: 0.75 !important;
}

.call_coccoc-alo-phone.call_coccoc-alo-green.call_coccoc-alo-hover
  .call_coccoc-alo-ph-img-circle,
.call_coccoc-alo-phone.call_coccoc-alo-green:hover
  .call_coccoc-alo-ph-img-circle {
  background-color: #75eb50;
}

@-moz-keyframes coccoc-alo-circle-anim {
  0% {
    transform: rotate(0) scale(0.5) skew(1deg);
    opacity: 0.1;
  }
  30% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.1;
  }
}

@-webkit-keyframes coccoc-alo-circle-anim {
  0% {
    transform: rotate(0) scale(0.5) skew(1deg);
    opacity: 0.1;
  }
  30% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.1;
  }
}

@-o-keyframes coccoc-alo-circle-anim {
  0% {
    transform: rotate(0) scale(0.5) skew(1deg);
    opacity: 0.1;
  }
  30% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.1;
  }
}

@keyframes coccoc-alo-circle-anim {
  0% {
    transform: rotate(0) scale(0.5) skew(1deg);
    opacity: 0.1;
  }
  30% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.1;
  }
}

@-moz-keyframes coccoc-alo-circle-fill-anim {
  0% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}

@-webkit-keyframes coccoc-alo-circle-fill-anim {
  0% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}

@-o-keyframes coccoc-alo-circle-fill-anim {
  0% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}

@keyframes coccoc-alo-circle-fill-anim {
  0% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}

@-moz-keyframes coccoc-alo-circle-img-anim {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}

@-webkit-keyframes coccoc-alo-circle-img-anim {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}

@-o-keyframes coccoc-alo-circle-img-anim {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}

@keyframes coccoc-alo-circle-img-anim {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}

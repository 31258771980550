
  .menu_icon{
    color: var(--text-white);
    margin-right: 1rem;
    font-size: 2rem;
  }


.dashboard{
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
    overflow: hidden;
    /* border-radius: 1rem; */
    background-color: rgba(84, 160, 255,1.0);
}

.dashboard_left_wrap{
    width: 400px;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.dashboard_left_profile{
    flex: .25;
    text-align: center;
    margin-top: 2rem;
}

.dashboard_left_profile img{
    height: 150px;
    width: 150px;
    border-radius:50% ;
    margin-bottom: 1rem;
}
.dashboard_left_profile h4{
    font-size: 1.5rem;
    line-height: 2rem;
    color: var(--primary-color-2);
    text-transform: capitalize;
}



.dashboard_left_menu{
    flex: .75;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    padding-left: .5rem;
}

.dashboard_left_menu a{
    display: flex;
    align-items: center;

    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 500;
    color: var(--text-white);
    border-bottom: 1px solid rgba(200, 214, 229,.5);
    transition: all .2s ease-in;
    padding: 1rem 2rem;
    border-radius: .3rem;
}
.dashbord_Active{
    font-weight: bold;
    background-color: rgba(46, 134, 222,1.0);
}

.dashboard_left_menu a:hover{
    background-color: var(--primary-color-2);
}

/* =========================================== */
.dashboard_right_wrap{
    overflow-x:auto ;
    width: 100%;
    min-width: 600px;
}

.dashboard_right_header{
    border-left: 1px solid rgba(245, 246, 250,1.0);
    height: fit-content;
    min-height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
}

.dashboard_right_header h3{
    font-size: 2rem;
}
.dashboard_right_header h3 span{
    color: rgba(238, 82, 83,1.0);
}

.dashboard_right_icon-wrap{
    display: flex;
    align-items: center;
    gap: 3rem;
}
.dashboard_right_icon-wrap> span{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    color: var(--text-white);
    border-radius: 50%;
    cursor: pointer;
    
    height: 55px;
    width: 55px;
}
.dashboard_right_icon-wrap> span:hover{
    background-color: rgba(46, 134, 222,1.0);;
}

.dashboard_right_icon-wrap> span> span{
    position: absolute;
    top: -.3rem;
    right: -.2rem;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    height: 1.8rem;
    width: 1.8rem;
    background: red;
    border-radius: 50%;
}

.dashboard_right_icon-wrap> span:nth-child(3){
    rotate: calc(90deg);
}


/* ================================== */
.dashboard_right_title-wrap{
    background: rgba(245, 246, 250,1.0);

    height: 90vh;
    overflow: auto;
    padding: 1rem;
}

.dashboard_right_title-wrap > h3{
    font-size: 1.5rem;
    display: table;
    border-bottom: 2px solid rgba(27, 156, 252,1.0);
}

/* dataTable =============== */

.dashboard_right_title_header,.dashboard_right_title-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dashboard_right_title_header{
    margin: 0.7rem 0;
    border-radius: .3rem;
}

.dashboard_right_title_header h3{
    color: gray;
    padding-bottom: .7rem;
    border-bottom: 2px solid;
    text-align: center;
}

.dashboard_right_title_header h3:nth-child(1){
    flex: .55;
}
.dashboard_right_title_header h3:nth-child(2){
    flex: .12;
}
.dashboard_right_title_header h3:nth-child(3){
    flex: .23;
}

.dashboard_right_title-content p{
    font-size: 1.2rem;
}
.dashboard_right_title-content p:nth-child(1){
    padding-left: .3rem;
    flex: .55;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.dashboard_right_title-content p:nth-child(2){
    flex: .12;
    text-align: center;
}
.dashboard_right_title-content p:nth-child(3){
    flex: .23;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.dashboard_right_title-content{
    border-radius: .4rem;
    margin-bottom: 1rem;
    padding: .5rem 0;
    border-bottom: 1px solid lightgray;
}
.dashboard_right_title-content:hover{
    background: rgb(222, 228, 250);
}





.table_col3 button{
    border: 0;
    font-size: 1.1rem;
    padding: .5rem 1rem;
    background-color: transparent;
    display: flex;
    gap: .5rem;
    cursor: pointer;
    border-radius: .3rem;
    color: gray;
}


.table_col3 button:nth-child(1):hover{
    color: #fff;
    background: rgba(156, 136, 255,1.0);
}
.table_col3 button:nth-child(2){
    color: rgba(232, 65, 24,1.0);
}
.table_col3 button:nth-child(2):hover{
    color: #fff;
    background: rgba(0, 168, 255,1.0);
}



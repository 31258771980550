.testimonials_container{
    text-align: center;
    width: 75%;
    margin: 0 auto 1rem auto;
}

.testimonials_container h4{
    font-size: 1.5rem;
    line-height: 27px;
    font-weight: 500;
    color: var(--primary-color-2);   
}

.testimonials_container > h2 {
    font-size: 2.6rem;
    line-height: 63px;
    margin-bottom: 2rem;
    padding-top: 2rem;

}

.testimonials_carousel{
  margin-top: .5rem;  
  position: relative;
}

/* Media crasol  */
.testimonials_cards{
    display: flex;
    gap: 2rem;
    padding: 1rem;

}
.testimonials_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 2rem 0;
     border-radius: .5rem;
     border-bottom: 2px solid var(--primary-color-2);
     box-shadow: 0 0 3px 3px rgba(0,0,0,.1);
     margin-bottom: 2rem;
}

.testimonials_card_img{
    width: 100px;
    height: 100px;
}
.testimonials_card_img > img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}



.testimonials_card > p{
    font-size: 1rem;
    color: var(--text-black);
}
.testimonials_card > h5{
    font-size: 18px;
    font-weight: 400;
    width: 75%;
}
.mySwiper{
    padding: .2rem !important; 
}


/* COUSTOME STYLE  */
.swiper-button-next::after,.swiper-button-prev::after{
    font-size: 1.5rem !important;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
}
.swiper-button-prev::after{
    padding-right: .2rem;
}


.swiper-button-next{
color: rgb(0, 0, 0) !important; 
right: -5% !important; 
}

.swiper-button-prev{
color: rgb(0, 0, 0) !important; 
 left: -5% !important;
}

.swiper-button-next, .swiper-button-prev{
    height: 50px !important ;
    width: 50px !important ;
    border-radius: 50%;
    transition: all .4s ease-in;
margin-top: -2rem !important;
}

.swiper-button-next:hover ,
.swiper-button-prev:hover {
    color: rgb(255, 255, 255) !important;
    background: var(--primary-color) !important;
}
/* .swiper-button-next:after{
    color: red !important;
} */



    .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
    text-align: center !important;
    pointer-events: auto !important;
}

.swiper-pagination-bullet-active{
    background: var(--primary-color) !important;
}

.swiper-horizontal>.swiper-pagination-bullets {
    width: 130px !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    -webkit-transform: translateX(-50%) !important;
    -moz-transform: translateX(-50%) !important;
    -ms-transform: translateX(-50%) !important;
    -o-transform: translateX(-50%) !important;
}


/* =========Responsive============= */

@media screen and (max-width:1400px) and (min-width:1025px){
    .testimonials_card > img{
        height: 35px !important;
        
    }

.swiper-button-next{
    right: -8% !important; 
    }
    
    .swiper-button-prev{
        left: -8% !important;
    }
    
}

@media screen and (max-width:1024px) and (min-width:681px){
   

.swiper-button-next{
    right: -10% !important; 
    }
    
    .swiper-button-prev{
        left: -10% !important;
    }



    .testimonials_card > img{
        height:2.5rem;
    }

  
    .testimonials_container > h4 {
        font-size: 1.5rem;
        line-height: 2.2rem;
    }
    
    .testimonials_container > h2 {
        font-size: 2rem;
        line-height: 2rem;
        margin-bottom: 2rem;
    }
}

@media screen and (max-width:680px){

    .testimonials_card > img{
        height:2rem;
    }

    .swiper-button-next,.swiper-button-prev{
        display: none !important;
    }
    .testimonials_container > h4 {
        font-size: 1.6rem;
        line-height: 2rem;
    }
    
    .testimonials_container > h2 {
        font-size: 1.6rem;
        line-height: 2rem;
        margin-bottom: 2rem;
    }
    
}

@media screen and (max-width:492px){
    .testimonials_card > img{
        display: none;
    }

    .testimonials_container{
        width: 100%;
        padding: 0 1rem;
    }

    .testimonials_container > h4 {
        font-size: 1.2rem;
        line-height: 1.5rem;
    }
    .testimonials_container > h2 {
        font-size: 1.15rem;
        line-height: 2rem;
        margin-bottom: .7rem;
    }
    .testimonials_card > h5{
        font-size: 1rem;
        text-align: start;
        font-weight: 400;
    }
}

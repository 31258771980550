.frequently_container {
  width: 80%;
  max-width: 1350px;
  margin: 0 auto 5rem auto;
}
.frequently_container > h2 {
  font-size: 2.6rem;
  text-align: center;
}
.frequently_content {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

.frequently_content-left {
  flex: 0.6;
}

.frequently_content_left-accordion {
  border-radius: 0.5rem;
  background-color: var(--primary-color);
}

.accordion_title {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.7rem 15%;
  border-radius: 0.5rem;
  margin-bottom: 0.8rem;
  cursor: pointer;
}
.accordion_title > h5 {
  font-size: 18px;
  font-weight: 400;
}

.accordion_icon {
  font-size: 22px;
}

.accordion_content {
  display: block;
  width: 68%;
  margin: 0 auto 10px auto;
  padding: 0 1.5rem 1rem 0;
  font-size: 16px;
  line-height: 24px;
}

/* frequently_content Form Css */
.frequently_content-right {
  flex: 0.4;
  color: var(--text-black);
  background-color: #fff;
  border-radius: 1rem;
}

.frequently_content-right h4 {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  padding: 0.8rem;
  color: var(--primary-color);
}

.frequently_content-right form {
  display: flex;
  flex-direction: column;
  padding: 2rem 3rem 1.5rem 3rem;
}

.frequently_content-right form label {
  font-size: 18px;
  margin: 0.5rem 0;
}

.frequently_content-right form input {
  color: var(--text-black);
  padding: 0.6rem 0.7rem;
  background: transparent;
  outline: none;
  border: 1px solid var(--text-black);
  border-radius: 0.3rem;
  font-size: 16px;
  letter-spacing: 1px;
}

.frequently_content-right form textarea {
  padding: 0.6rem 0.7rem;
  border-radius: 0.3rem;
  background: transparent;
  height: 143px;
  border: 1px solid var(--text-black);
  font-size: 16px;
}
.frequently_content-right form button {
  font-size: 18px;
  color: var(--text-white);
  background-color: var(--primary-color);
  border: 0;
  padding: 0.8rem;
  border-radius: 0.3rem;
  margin-top: 1rem;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.frequently_content-right form input:focus,
.frequently_content-right form textarea:focus {
  box-shadow: 0 0px 0.4rem rgb(85, 88, 90);
}

.frequently_content-right form button:hover {
  background-color: var(--primary-color-2);
}

.error {
  outline: none;
  border: 1px solid rgba(255, 0, 0, 0.322) !important;
}
.qustion_message {
  color: var(--primary-color-2);
  font-weight: 500;
  padding-top: 0.5rem;
  text-align: center;
}
/* ===========Responive============= */
@media only screen and (max-width: 1600px) and (min-width: 1201px) {
  .frequently_content-right {
    border-radius: 0.6rem;
  }
  .frequently_content-right form {
    padding: 0.8rem;
  }
}

@media only screen and (max-width: 1201px) and (min-width: 1025px) {
  .frequently_container {
    padding: 3rem;
    margin-bottom: 3rem;
    width: 100%;
  }

  .frequently_content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
  }

  .frequently_content-left {
    flex: auto;
    width: 100%;
  }
  .frequently_content-right {
    flex: auto;
    width: 100%;
  }
}

@media only screen and (max-width: 1025px) and (min-width: 769px) {
  .frequently_container {
    width: 100%;
    padding: 3rem;
    margin-bottom: 3rem;
  }

  .frequently_content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
  }

  .frequently_content-right {
    flex: auto;
    width: 100%;
  }
}

@media only screen and (max-width: 769px) {
  .frequently_container {
    width: 100%;
    padding: 2rem;
    margin-bottom: 3rem;
  }

  .frequently_container > h2 {
    font-size: 2rem;
  }
  .frequently_content-left {
    flex: auto;
    width: 100%;
    padding: 0 1rem;
  }

  .frequently_content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .frequently_content-right {
    flex: auto;
    width: 100%;
  }

  .frequently_content-right form {
    padding: 2rem;
  }

  .accordion_content {
    width: 100%;
    text-align: center;
    padding: 0 1.5rem 1.5rem 1.5rem;
  }

  .frequently_container {
    padding: 1rem;
  }
  .frequently_content {
    margin-top: 1rem;
  }

  .accordion_content {
    padding: 0 0.5rem 0.5rem;
  }

  .frequently_content-right {
    border-radius: 0.5rem;
  }
  .frequently_content-right form {
    padding: 0.8rem 1rem;
  }
}

@media only screen and (max-width: 480px) {
  .frequently_container > h2 {
    font-size: 1.2rem;
    margin-top: 0.5rem;
  }
  .frequently_container {
    padding: 0.5rem;
  }
  .frequently_content {
    margin-top: 1rem;
  }

  .accordion_content {
    padding: 0 0.5rem 0.5rem;
  }
  .frequently_content-right h4 {
    font-size: 1.2rem;
  }
  .frequently_content-right {
    border-radius: 0.2rem;
  }
  .frequently_content-right form {
    padding: 0.8rem;
  }
}

.login{
    width: 100vw;
    min-height: 100vh;
    background-color: var(--text-white);

    display: flex;
    justify-content: center;
    align-items: center;
}


.login_container{
    border: 1px solid lightgray;
    border-radius: .8rem;
    box-shadow:  0 0 .3rem lightgray;

    padding: 6rem 12rem;
    text-align: center;

}

.login_container img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 1rem;
}

.login_container h3{
    font-size: 2rem;
    line-height: 2.5rem;
    text-transform: capitalize;
    color: var(--text-black);

    margin-bottom: 4rem;
}

.login_container form{

    display: flex;
    flex-direction: column;
    gap: 3rem;

    margin: 2rem 0;
}

.login_container form input{
    outline: none;
    border: 1px solid lightgray;
    border-radius: .5rem;
    padding: .7rem 1rem;
    height: 56px;
    font-size: 1.2rem;
    letter-spacing: 1px;
    width: 100%;
}

.login_container form p{
    text-align: start;
    padding-top: .5rem;
}

.login_container form input:focus{
    box-shadow: 0 0 .2rem  var(--primary-color);
}

.login_container form button{
    color: var(--text-white);
    width: 100%;
    background-color: var(--primary-color);
    padding: .7rem 5rem;
    border: none;
    border-radius:.5rem;
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: 1px;
    align-self: center;
    cursor: pointer;
    margin-top: .3rem;

    transition: all .3s ease-in;
}

.login_container form button:hover{
    color: var(--text-white);
    background-color: var(--primary-color-2);
}

.login_container .error_message{
    color: red;
}

.login_container .error{
    box-shadow: 0 0 .2rem  red;
}


.button_wrap{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}
.user_subscriber_container{
    height: 80vh;
    min-height: fit-content;
    width: 100%;
    overflow-y:auto ;
}

.user_subscriber_wrap{
    width: 100%;
    height: 100%;
}

.user_subscriber_header{
display: flex;
align-items: center;
font-size: 1.1rem;
gap: .7rem;
margin-top: 1.5rem;
color: gray;
}

.user_subscriber_header h3{
    flex: .5;
    border-bottom: 1px solid lightgray;
}

.user_subscriber_content{
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    gap: .7rem;
    margin-top: .4rem;
    
    padding: .4rem 1rem;
    border-radius: .4rem;
    border-bottom: 1px solid lightgray;
}
.user_subscriber_content:hover{
    background-color: rgba(162, 155, 254,.1);
}

.user_subscriber_content p{
    flex: .5;
    font-size: 1.2rem;
}

.user_subscriber_content p button{
    border: 0;
    background-color: rgba(255, 0, 0, 0.409);
    font-size: 1.2rem;
    padding: .3rem 1rem;
    border-radius: .3rem;
    cursor: pointer;
}
.user_subscriber_content p button:hover{
    background-color: red;
    font-weight: 500;
}
.user_subscriber_content p a{
    text-transform: lowercase;
}

.user_subscriber_content p a:hover{
   text-decoration: revert;
}
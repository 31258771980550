.hero_container {
  background-image: url('../../../public/assets/Vector 15.png');
  background-repeat: no-repeat;
  background-position-x: 100%;
}

.hero_main {
  width: 80%;
  margin: 0 auto;
}

/* Start Nav Css */

.hero_banner {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin: 30px 0;
}

.hero_banner-left {
  flex: 1;
  position: relative;
  margin-top: 5%;
}

.hero_banner-left > h1 {
  font-size: 30px;
  line-height: 85px;
  font-weight: 700;
  color: var(--text-black);
}

.hero_banner-left > h1 > span {
  color: var(--primary-color-2) !important;
}

.hero_banner-left > p {
  font-size: 1rem;
  line-height: 24px;
  color: var(--parragraph-color);
  margin: 3rem 0 3rem 0;
  width: 75%;
}

.hero_donload_app_wrap {
  width: fit-content;
  max-width: 100%;
  height: fit-content;
  max-height: 100%;

  display: flex;
  gap: 1.5rem;
}
.hero_donload_app_user,
.hero_donload_app_vendor {
  border: 1px solid #1978f41a;
  border-top: 0;
  border-radius: 1rem;
  position: relative;
}

.hero_donload_app_user h4,
.hero_donload_app_vendor h4 {
  font-size: 0.8rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0.8rem;
}

.style_dot {
  position: absolute;
  width: 100%;
  display: flex;
  gap: 80%;
  top: 0.5rem;
  left: 0;
}

.style_dot span {
  background: #1978f41a;
  height: 1px;
  width: 100%;
}
.style_dot span:nth-child(2) {
  margin-right: -1px;
}

.style_dot span::before {
  content: '';
  height: 0.5rem;
  width: 0.5rem;
  background: #1978f4;
  display: block;
  border-radius: 50%;
  animation: pulse 2s infinite;
  animation-delay: 1s;
  box-shadow: 0 0 0 0.3em transparent, 0 0 0 0.6em transparent;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--primary-color-2),
      0 0 0 0em var(--parragraph-color);
  }
}

.style_dot span:nth-child(1)::before {
  margin-left: auto;
  transform: translateY(-45%);
}
.style_dot span:nth-child(2)::before {
  margin-right: auto;
  transform: translateY(-45%);
}

.hero_donload_app_user_icon,
.hero_donload_app_vendor_icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0.4rem 1rem;
  gap: 1rem;
}

.hero_donload_app_user_icon a img,
.hero_donload_app_vendor a img {
  max-width: 100px;
  max-height: 40px;
}

/* ===old==== */

.hero_banner-right {
  flex: 1;
  display: flex;
  gap: 2rem;
  justify-content: flex-end;
}

.hero_banner_right-poster {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.hero_banner_right-poster > img {
  box-shadow: 0 0 1rem -10px;
  height: 100%;
}

.hero_banner_right-social {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.hero_banner_right-social .hero_banner_right-line {
  height: 13%;
  width: 1px;
  background-color: var(--text-white);
}

.hero_banner_right-social > a {
  font-size: 1.5rem;
  color: var(--text-white);
}


/* hero animation style ============ */

.hero_animatin-1,
.hero_animatin-4 {
  position: absolute;
  rotate: calc(30deg);
  border-top: 1rem solid red;
  border-right: 0.7rem solid transparent;
  border-left: 0.7rem solid transparent;
}

.hero_animatin-1 {
  top: 0;
  right: 47%;

  animation: rotate-scale-up 10s linear both infinite;
}

.hero_animatin-4 {
  bottom: 40%;
  right: -15%;
  animation: rotate-scale-up 10s linear both infinite;
  -webkit-animation: rotate-scale-up 10s linear both infinite;
}

.hero_animatin-2,
.hero_animatin-3 {
position: absolute;
font-size: 3rem;
font-weight: 900;
}

.hero_animatin-2 {
  top: 45%;
  left: 15%;
  color: var(--primary-color-2);
  animation: rotate-scale-up 10s linear both infinite;
}
.hero_animatin-3 {
  top: 30%;
  right: 30%;
  color: var(--primary-color);
  rotate: calc(15deg);

  animation: rotate-scale-up 10s linear both infinite;
}

/* Animation css =======================*/

@keyframes heroAnimation {
  0% {
    margin-top: -1rem;
  }
  25% {
    margin-left: -1rem;
  }
  75% {
    margin-right: 1rem;
  }
  100% {
    margin-top: 1rem;
  }
}

@keyframes rotate-scale-up {
  0% {
    transform: scale(0.8) rotateZ(0);
  }
  50% {
    transform: scale(1.2) rotateZ(180deg);
  }
  100% {
    transform: scale(0.8) rotateZ(360deg);
  }
}

@keyframes rotate-scale-up-ver {
  0% {
    transform: scale(0.8) rotateY(0) rotateZ(0);
  }
  50% {
    transform: scale(1.2) rotateY(180deg) rotateZ(-180deg);
  }
  100% {
    transform: scale(0.8) rotateY(360deg) rotateZ(-360deg);
  }
}

.app_icons {
  color: aliceblue;
  margin-top: 1rem;
  display: none;
  gap: 1rem;
  justify-content: center;
}

.app_icons a {
  font-weight: 500;
  text-decoration: underline;
}

.hero_banner_right-poster span a {
  text-decoration: underline;
}
.bunner-slider{
  width: 525px;
}
/* ===========Responive============= */
@media only screen and (max-width: 1535px) and (min-width: 1280px) {
  .hero_container {
    background-size: 50%;
  }
  .hero_banner-left > h1 {
    font-size: 2.4rem;
    line-height: 3.5rem;
  }
  .hero_banner-left > p {
    margin: 0 0 1.5rem;
    width: 100%;
  }
}

@media only screen and (max-width: 1279px) and (min-width: 1024px) {
  .hero_container {
    background-size: 50%;
  }

  .hero_banner-right {
    gap: 2rem;
  }
  .hero_banner-left > h1 {
    font-size: 3.5rem;
    line-height: 4rem;
  }
  .hero_banner-left > h1 {
    font-size: 2.5rem;
    line-height: 3rem;
  }
  .hero_banner-left > p {
    margin: 1rem 0 1rem 0;
    width: 100%;
  }
  .hero_banner-left > button {
    background-color: var(--primary-color-2);
  }
}

@media only screen and (max-width: 1025px) and (min-width: 769px) {
  .hero_main {
    width: 100%;
    padding: 0 2rem;
  }
  .hero_banner {
    flex-direction: column;
    justify-content: center;
    gap: 4rem;
  }

  .hero_banner-left {
    text-align: center;
  }
  .hero_banner-left > p {
    width: 100%;
    margin: 1rem;
  }
  .hero_banner-left > button {
    background: var(--primary-color-2);
  }

  .hero_banner-right {
    justify-content: center;
  }
  .hero_banner_right-poster > img {
    height: auto;
  }

  .hero_animatin-1 {
    right: 10%;
  }
  .hero_animatin-2 {
    top: 25%;
  }
  .hero_animatin-3 {
    right: 3%;
    top: 50%;
    color: white;
  }
  .hero_animatin-4 {
    right: 15%;
    bottom: 5%;
  }

  .hero_banner_right-social {
    display: none;
  }

  .hero_banner-left > h1 {
    font-size: 3rem;
    line-height: 3.8rem;
  }

  .hero_banner_right-poster > img {
    height: 30rem;
    width: 18rem;
    margin-top: -2rem;
  }
  .hero_donload_app_wrap {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 769px) {
  .hero_container {
    background:linear-gradient(0deg, rgba(56, 211, 231, 0.712), rgba(30, 191, 202, 0.514)), url('../../../public/assets/hero_banner/mobile-image.jpg');
    background-position: center;
  }
  .hero_main {
    width: 100%;
    padding: 0 2rem;
    margin-bottom: 5rem;
  }
  .hero_banner {
    flex-direction: column;
    justify-content: center;
    gap: 4rem;
  }

  .hero_banner-left {
    text-align: center;
  }
  .hero_banner-left > h1 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  .hero_banner-left > p {
    width: 100%;
    margin: 0rem 0 2rem 0;
  }
  .hero_banner-left > button {
    background: var(--primary-color-2);
    padding: 0.5rem 1.5rem;
  }

  .hero_animatin-1 {
    right: 10%;
    display: none;
  }
  .hero_animatin-2 {
    top: 25%;
    display: none;
  }
  .hero_animatin-3 {
    display: none;
    right: 3%;
    top: 50%;
    color: white;
  }
  .hero_animatin-4 {
    display: none;
    right: 15%;
    bottom: 5%;
  }

  .hero_banner_right-social {
    display: none;
  }

  .hero_banner-right {
    justify-content: center;
  }
  .hero_banner_right-poster {
    height: fit-content;
  }
  .hero_banner_right-poster > img {
    width: 100%;
    max-height: 600px;
  }
  .hero_banner_right-poster {
    display: none;
  }
  .app_icons {
    display: flex;
    font-weight: 500;
  }
  .app_icons_inner span {
    display: block;
    margin-bottom: 0.3rem;
  }

  .app_icons > a {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.7rem;
  }

  .app_icons_inner-icon a {
    font-size: 1.5rem;
    cursor: pointer;
  }
  .app_icons_inner-icon a:nth-child(1) {
    color: #3cff00;
    margin-right: 0.5rem;
  }
  .hero_banner-left > p {
    color: #ffffff;
  }
  .hero_container {
    background-size: 250%;
  }
  .hero_donload_app_wrap {
    display: none;
  }
}

@media only screen and (max-width: 420px) {
  .hero_banner-left > h1 {
    font-size: 1.5rem;
    line-height: 1.8rem;
    margin-bottom: 0.5rem;
  }
  .app_icons {
    display: flex;
    margin-top: 1rem;
  }

  .app_icons > a {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.7rem;
  }

  .app_icons > a > a {
    font-size: 1.5rem;
    cursor: pointer;
  }
  .app_icons > a > a:nth-child(1) {
    color: #3cff00;
  }

  .hero_banner_right-poster {
    display: none;
  }
  .hero_banner-left > p {
    color: #ffffff;
    margin-bottom: 1rem;
  }

  .hero_container {
    background-size: 350%;
  }
  .hero_banner {
    gap: 2.5rem;
  }
}

.cardThree{
    width: 100%;
    height: fit-content;
    margin: 0 auto;
    display: flex;
    gap: 3rem;
}

.cardThree_content{
    flex: 1;
}

.cardThree_content_header h3{
    font-size: 32px;
    font-weight: 500;
    line-height: 39px;
    margin-bottom: 2rem;
}

.cardThree_content_parragraph{
    display: flex;
    gap: 1rem;
    /* margin-top: 16px; */
}

.cardThree_content_parragraph p{
    font-size: 1rem;
    line-height: 26.82px;
}

.arrowIcon{
   width: 100px;
   height: 100px;
   position: relative;
   margin-right: .5rem;
}

.arrowIicon1,.arrowIicon2{
    border-top:20px solid transparent ;
    border-bottom:20px solid transparent ;
}

.arrowIicon1{
    border-left:30px solid var(--primary-color-2) ;
    position: absolute;
    top: 0;
    left: 15%;
}

.arrowIicon2{
    border-left:30px solid var(--primary-color) ;
    position: absolute;
    top: 0;
    left: -4px;
}


@media only screen and (max-width:1600px) {
    .space_1{
        margin-bottom: 3rem;
    }
}
@media only screen and (max-width:1600px) and (min-width:769px){
    .cardThree{
        width: 80%;
        gap: 1rem;
    }
    .cardThree_content_header h3{
        font-size: 1.39rem;
    }
    .arrowIicon1 {
        border-left: 20px solid var(--primary-color-2);
    }
    .arrowIicon2 {
        border-left: 20px solid var(--primary-color);
    }
    .arrowIicon1, .arrowIicon2 {
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
    }
}
@media only screen and (max-width:1025px){
    .cardThree{
        width: 100%;
        padding: 1.5rem;
        flex-direction: column;
        align-items: center;
        justify-content: center; 
    }
    .cardThree_content_header h3{
        font-size: 1.6rem;
    }
    .arrowIcon{
        width: 60px;
        height: 60px;
        margin-right:.2rem;
        margin-top: .2rem;
     }
     
     .arrowIicon1,.arrowIicon2{
         border-top:.7rem solid transparent ;
         border-bottom:.7rem solid transparent ;
     }
     
     .arrowIicon1{
         border-left:1rem solid var(--primary-color-2) ;
         position: absolute;
        
     }
     
     .arrowIicon2{
         border-left:1rem solid var(--primary-color) ;
        left: -2px;
         
     }
}

@media only screen and (max-width:600px) {
    .cardThree_content_header h3{
        font-size: 1.1rem;
        font-weight: 600;
        line-height: 1.5rem;
        margin-bottom: .5rem;
        
    }
   
    

    .arrowIcon{
        width: 60px;
        height: 60px;
        margin-right:.2rem;
        margin-top: .2rem;
     }
     
     .arrowIicon1,.arrowIicon2{
         border-top:.6rem solid transparent ;
         border-bottom:.6rem solid transparent ;
     }
     
     .arrowIicon1{
         border-left:15px solid var(--primary-color-2) ;
         position: absolute;
        
     }
     
     .arrowIicon2{
         border-left:15px solid var(--primary-color) ;
         left: -4px;
         
     }

}
    
.nav_container {
  width: 100%;
  height: fit-content;
}
.navbar-header {
  background-color: var(--primary-color);
  width: 100%;
}

.top_nav {
  height: 33px;
  margin: 0 auto;
  width: 80%;
  padding: 20px 0;
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 999;
}

.top_nav > a {
  font-family: var(--sub-font) !important;
}

.top-logo p {
  font-size: 18px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}

.navbar > a > img {
  max-width: 130px;
  margin-left: -11px;
}
.navbar_menu {
  margin-right: -22px;
}
/* navbar position style  */
.sticky {
  position: fixed;
  top: 0;
  max-width: 1920px;
  width: 100%;
  padding: 0 10%;
  background-color: #ffffff;
  box-shadow: 0rem 1px 1rem 1px rgba(0, 0, 0, 0.15);
  z-index: 99999999 !important;
  animation: navAlimation 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.navActive {
  font-weight: 500;
  color: var(--text-white) !important;
  background-color: var(--primary-color-2);
}

.navActive1 {
  font-weight: 500;
  background: #ff553b9f;
}

@keyframes navAlimation {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.navbar_menu > nav > a {
  display: inline-block;
  padding: 0.4rem 0.7rem;
  border-radius: 0.2rem;
  font-size: 18px;
  font-weight: 600;
}

.navbar_menu > nav > a:hover {
  color: var(--text-black);
  background: rgb(235, 235, 235);
}

.navbar_menu > nav a:last-child {
  margin-right: 0.5rem;
}

/* Nested Menu Setup ========= */
.navbar_menu .nested_menu {
  position: relative;
}

.navbar_menu .nested_menu ul {
  width: 430px;
  padding: 0.5rem;
  border-radius: 0.5rem;
  list-style: none;
  background: var(--text-white);
  display: none;
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 2.2rem;
  z-index: 222;
}

.navbar_menu .nested_menu:hover ul {
  display: block;
  pointer-events: auto;
  animation: navAlimationHover 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@keyframes navAlimationHover {
  0% {
    opacity: 0.2;
    transform: translateY(3rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0rem);
  }
}

.navbar_menu .nested_menu > ul li a {
  width: 100%;
  display: block;
  color: var(--text-black);
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
}

.navbar_menu .nested_menu > ul li a:hover {
  font-weight: 500;
  background: lightgray;
}

.navbar.sticky a {
  color: #343434;
}
/* ========= Mobile Menu css=============== */
.navbar button {
  border: 0;
  outline: 0;
  color: var(--primary-color-2);
  font-size: 2rem;
  cursor: pointer;
  background: transparent;
}

.mobile_menu-container {
  color: #000;
  font-weight: 500;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: end;
  padding: 0 2rem;
}
.mobile_sticky {
  position: fixed !important;
  top: 12%;
  z-index: 999;
}

.mobile_menu nav {
  animation: navCardAnimation 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.nested_animation1 {
  overflow: hidden;
  animation: navNesterdCardAnimation 1s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
}
@keyframes navCardAnimation {
  0% {
    opacity: 0.2;
    transform: translateY(25%);
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes navNesterdCardAnimation {
  0% {
    height: 2rem;
  }
  99% {
    height: 27.5rem;
  }
  100% {
    min-height: fit-content;
    max-height: 100%;
  }
}

.mobile_menu {
  position: absolute;

  top: 0;
  bottom: 0;
  right: 0.2rem;
  z-index: 55555;
}

.mobile_menu nav {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  border-radius: 0.3rem;
  background-color: rgb(253, 253, 253);

  padding: 1rem;
  box-shadow: 0rem 1px 1rem 1px rgba(0, 0, 0, 0.15);
}

.mobile_menu nav a {
  padding: 0.5rem 1rem;
  font-size: 18px;
  border-radius: 0.5rem;
}

.mobile_menu nav > a:hover {
  background-color: var(--primary-color);
}
.mobile_menu nav ul a:hover {
  background-color: var(--primary-color-2);
}

.nested_mobile > ul {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  list-style: none;
}

.nested_mobile > ul > li a {
  display: block;
}
.navbar button {
  display: none;
  padding-top: 3px;
}

.mobile_menu nav {
  display: none;
}

.mobile_stiky {
  animation: navAlimation 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  box-shadow: 0rem 1px 1rem 1px rgba(0, 0, 0, 0.15);
  z-index: 10000;
}

/* ===========Responive============= */
@media only screen and (min-width: 1536px) {
}
@media only screen and (max-width: 1535px) and (min-width: 1280px) {
  .navbar-header{
    padding: 0 10%;
  }
  .top_nav {
    width: 100%;
  }
  .navbar {
    padding: 0 10%;
    width: 100%;
  }
}

@media only screen and (max-width: 1279) and (min-width: 1024px) {
  .navbar-header {
    padding: 0 3rem;
  }
  .top_nav {
    width: 100%;
  }
  .navbar {
    width: 100%;
    padding: 0 3rem;
  }
}

@media only screen and (max-width: 1023px)  {
  .sticky {
    opacity: 0 !important;
    pointer-events: none;
  }
  .mobile_menu nav {
    display: flex;
  }
  .mobile_stiky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
  .navbar_menu nav {
    display: none;
  }

  .top_nav {
    display: none;
  }

  .navbar {
    width: 100%;
    padding: 0 1rem;
    height: 4rem;
    background-color: white;
  }

  .mobile_menu-container,
  .navbar button {
    display: block;
  }

  .navbar a img {
    transform: scale(0.6);
  }

  .mobile_stiky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media only screen and (max-width: 767) and (min-width: 640px) {
}

@media only screen and (max-width: 639px) and (min-width: 576px) {
  .navbar_menu nav {
    display: none;
  }

  .top_nav {
    display: none;
    height: fit-content;
    padding: 0.5rem 1rem;
    text-align: center;
  }

  .navbar {
    width: 100%;
    padding: 0 1rem;
    background-color: #ffffff;
    height: 4rem;
  }

  .mobile_menu-container,
  .navbar button {
    display: block;
  }

  .navbar a img {
    transform: scale(0.6);
  }
  .mobile_stiky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media only screen and (max-width: 575px) {
  .navbar_menu {
    display: none;
  }

  .mobile_menu-container,
  .navbar button {
    display: block;
  }

  .top_nav {
    display: none !important;
  }

  .navbar {
    width: 100%;
    padding: 0 1rem;
    background: #ffffff;
    height: 4rem;
  }

  .mobile_menu-container,
  .navbar button {
    display: block;
  }

  .navbar a img {
    width: 80px;
    height: 80px;
  }
  .mobile_stiky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

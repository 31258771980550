@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:wght@900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  background-color: #fff;
}
:root {
  --text-black: #313030;
  --text-white: #f6f6f6;
  --parragraph-color: #525252;
  --primary-color: #2abfc4;
  --primary-color-2: #f1634c;
  --secondary-color: #283084;
  --main-font: 'Montserrat', sans-serif;
  --sub-font: 'Lexend', sans-serif;
  --gradient: linear-gradient(137.83deg, #f1634c -12.52%, #1977f4 105.16%);
}

body {
  max-width: 1920px;
  margin: 0 auto;
  height: fit-content;
  max-height: 100%;
  font-family: var(--main-font);
  position: relative;
}

a {
  text-decoration: none;
  color: inherit;
}

.space_1 {
  margin-bottom: 6rem;
}

.star {
  color: red;
}

.messages_success {
  margin-top: 0.3rem;
  text-align: center;
  color: var(--primary-color-2);
}

.error_border {
  border: 1px solid red !important;
}

.alice-carousel__dots-item:not(.__custom):hover,
.alice-carousel__dots-item:not(.__custom).__active {
  background-color: var(--primary-color) !important;
}

/* sertion spaching=== */
.section_spceing {
  margin-top: 3.125rem;
}

/* mobile_menu-container mobile_sticky */
.mobile_sticky {
  display: none !important;
}

/* Layoutes */
.container-main{
  width: 80%;
  margin: 0 auto;
}
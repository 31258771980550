.newsletterSubscribe{
    margin: 0 auto;
    color: var(--text-black);
}
.newsletterSubscribe h3{
    font-size: 2rem;
    font-weight: 500;
    line-height: 39.01px;
}

.newsletterSubscribe_content{
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 2rem;
}
.newsletterSubscribe_content h2{
    flex: .5;
    font-size: 2.6rem;
    line-height: 63px;

    padding-right: 4%;
}
.newsletterSubscribe_content_form{
    flex: .5;

}

.newsletterSubscribe_content_form form{
    width: 515px;
    height: fit-content;
    
    border-radius: .4rem;    
    display: flex;

    margin-left: auto;
    
}

.newsletterSubscribe_content_form form input{
    flex: .7;
    padding: 1rem;
    border:0;
    outline: 0;
    font-size: 1rem;
    box-shadow: 0 0 2px .7px rgba(133, 133, 133, 0.2);



    background: var(--text-white);
    border-top-left-radius: .4rem;
    border-bottom-left-radius: .4rem;
}
.newsletterSubscribe_content_form form input:focus{
    border: .3px solid var(--primary-color);
    box-shadow: 0 0 4px 0px var(--primary-color);
}

.newsletterSubscribe_content_form form button{
    flex: .3;
    border: 0;
    font-size: 1.2rem;
    color: var(--text-white);
    cursor: pointer;
    transition: all .3s ease-in;
    background-color: var(--primary-color);
    box-shadow: 0 0 2px 1px rgba(133, 133, 133, 0.3);

    border-top-right-radius: .4rem;
    border-bottom-right-radius: .4rem;
}

.newsletterSubscribe_content_form form button:hover{
    background-color: var(--secondary-color);
}


/* ===========Responsive============ */

@media screen and (max-width:1600px) and (min-width:1025px) {
    .newsletterSubscribe_content h2{
        font-size: 2rem;
        line-height: 2.5rem;
    }

    .newsletterSubscribe_content{
        margin-top: 1.5rem;
    }
}
@media screen and (max-width:1100px) and (min-width:769px) {
    .newsletterSubscribe_content{
        flex-direction: column;
        gap: 1rem;
        margin-top: 1rem;
    }
    .newsletterSubscribe_content h2{
        font-size: 2rem;
        line-height: 1.5rem;
    }

}
@media screen and (max-width:769px) and (min-width:481px) {
    .newsletterSubscribe{
        width: 100%;
        padding: 0 2rem;
        text-align: center;
    }

    .newsletterSubscribe h3{
        font-size: 1.5rem;
    }

    .newsletterSubscribe_content{
        margin-top: 1rem;
        flex-direction: column;
        gap: 1rem;
    }
    .newsletterSubscribe_content h2{
        font-size: 1.5rem;
        line-height: 2rem;
    }

    .newsletterSubscribe_content_form form{
        width: 90%;
        margin: 0 auto;
    }

    .newsletterSubscribe_content_form form button{
        padding: .7rem ;
    }
}
@media screen and (max-width:481px) {
  
    .newsletterSubscribe_content_form form input{
        width: 100%;
        padding: .6rem .8rem; 
    }
    .newsletterSubscribe_content_form form button{
        padding:0 .5rem
    }
    .newsletterSubscribe{
        width: 100%;
        padding: 0 1rem;
        text-align: center;
    }

    .newsletterSubscribe h3{
        font-size: 1.5rem;
        line-height: 1rem;
    }

    .newsletterSubscribe_content{
        margin-top: 1rem;
        flex-direction: column;
        gap: 1rem;
    }
    .newsletterSubscribe_content h2{
        font-size: 1.5rem;
        line-height: 2rem;
    }

    .newsletterSubscribe_content_form form{
        width: 300px;
        margin: 0 auto;
    }


}
    
.home_container{
    width: 100%;
    height: 100%;
}

/* Our services section css in home page */
.our_services{
    text-align: center;
    padding: 0 3rem;
    margin: 2rem auto 3rem;
}

.our_services > h4{
    font-size: 2rem;
    color: var(--primary-color-2);
}
.our_services > h2{
    font-size: 2.7rem;
    color: var(--text-black);
}

.our_services_posts{
    height: 100%;
    min-height: fit-content;
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2rem;
}





/* ===========Responive============= */
@media only screen and (max-width:1920px) and (min-width:1601px){
    .our_services{
        max-width: 80%;
        padding: 0;
    }
}
@media only screen and (max-width:1600px) and (min-width:1301px){
    .our_services{
        max-width: 80%;
        padding: 0;
    }
}
@media only screen and (max-width:1300) and (min-width:1201px){
    .our_services{
        width: 80%;
        padding: 0 3rem;
    }
}

@media only screen and (max-width:1200px) and (min-width:1025px){
    .our_services{
        /* width: 100%; */
        padding: 0 3rem;
    }
    .our_services_posts{
        grid-template-columns: repeat(3,1fr);
    }
}

@media only screen and (max-width:1025px) and (min-width:769px){

    .our_services{
        width: 100%;
        padding: 0 2rem;
        margin-top: 2rem;
        
    }

    .our_services > h2{
        font-size: 2.2rem;
    }
    .our_services_posts{
        grid-template-columns: repeat(2,1fr);
    }

    .postCard_container{
        height: fit-content;
    }
    .postCard_img{
        height: 50%;
    }
    .postCard_content{
        height: 50%;
    }
}
@media only screen and (max-width:769px){
    .our_services{
        width: 100%;
        padding: 0 1rem;
        margin-top: -3rem;
    }
    .our_services > h4{
        font-size: 1rem;
    }

    .our_services > h2{
        font-size: 1.5rem;
    }

    .our_services_posts{
        grid-template-columns: repeat(1,1fr);
    }  
    
}

@media only screen and (max-width:470px){
    .our_services > h2{
        font-size: 1rem;
    }
}
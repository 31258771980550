.cardOne{
    width: 80%;
    height: 475px;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;
}
.cardOne_img{
    flex: .35;
    border-radius: .7rem;
}

.cardOne_img img{
    height: 100%;
    width: 100%;
}

.cardOne_content{
    flex: .55;
    color: var(--text-black);
}

.cardOne_content h4{
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 26.82px;
}
.cardOne_content p{
    font-size: 16px;
    line-height: 24px;
}

.cardOne_content_title > h4{
    color: var(--primary-color-2);
}

.cardOne_content_title  h2{
    font-size: 2.6rem;
    font-weight: 600;
    line-height: 63px;

    margin-bottom: 1rem;
}


.cardOne_content_parragraph{
    margin-top: 1rem;
}

.cardOne_content_parragraph h4{
    margin-bottom: .5rem;
}



/* =========Responsive========= */

@media only screen and (max-width:1600px) and (min-width:1025px){
    .cardOne{
        height: fit-content;
        align-items: center;
        justify-content: center;
        gap: 2rem;
    }
    .cardOne_img img{
        min-height: 25rem;
    }
    .cardOne_content{
        flex: .65;
    }
}

@media only screen and (max-width:1025px) and (min-width:769px){
    .cardOne{
        padding: 0 3rem !important;
    }
    .cardOne_img{
        width: 700px !important;
        /* max-width: 100%; */
        margin: 0 auto;
    }
}
@media only screen and (max-width:1025px){
    .cardOne{
        width: 100%;
        padding:1rem ;
        height: fit-content;

        flex-direction: column;
        gap: 1rem;
        margin-top: -2.5rem;
    }
    .cardOne_content_title  h4{
        font-size: 1.2rem;
        line-height: 1.2rem;
    }

    .cardOne_content_title  h2{
        font-size:1.6rem;
        line-height: 2rem;
        margin-bottom: .2rem;
    }
    .cardOne_img{
        margin: 0;
    }
    .cardOne_img img{
        width: 70%; 
        max-width: 100%;  
        overflow: hidden;
        }
   
}


@media only screen and (max-width:600px){
    .cardOne_content_title  h2{
        font-size: 1.2rem;
        line-height: 2.5rem;
    }   
    .cardOne_content_title  h4{
        font-size: 1rem;
        margin-top: .5rem;
        line-height: 0rem;
    }

    .cardOne_content_parragraph h4{
     font-size: 1.2rem;
     line-height: 1rem;   
    }
    .cardOne_img img{
        height: 270px;
        width: 100%; 
        max-width: 100%;  
        overflow: hidden;
        }
}
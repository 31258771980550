/* Sort Component Style Label and Inputs */
.RegistrationInput_wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.8rem;

  margin-bottom: 1rem; /*Deleted this line*/
}

label {
  font-size: 18px;
  line-height: 27px;
}

.RegistrationInput_wrap input {
  outline: none;
  border: 1px solid lightgray;
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;

  font-size: 18px;
  letter-spacing: 1px;
}

.register_message p {
  text-align: center;
  padding: 0.25rem 0;
}
/* Sort Component Style Label and Inputs */

.registration_line {
  width: 100%;
  border: 1px solid lightgray;
}

.registration-header {
  width: 80%;
  margin: 0 auto;
}
.registration-header h2 {
  font-size: 2.6rem;
  line-height: 63px;
}

.registration-header h5 {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
}

.registration_form_wrap {
  width: 80%;
  margin: 2rem auto;
  padding: 1rem 2rem;
  border-radius: 0.7rem;
  box-shadow: 0 0 7px 1px rgba(133, 133, 133, 0.2);
}

.registration_form_wrap form {
  padding: 0 4rem;
}

/* test Try Css */
.registration_form-dateAge {
  margin-bottom: 1rem;
}
.registration_form-dateAge > label {
  display: block;
  margin-bottom: 1rem;
}
.registration_form_dateAge-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.registration_form_dateAge_wrap-left {
  display: flex;
  justify-content: flex-start;
  gap: 20%;
}

.registration_form_dateAge_wrap-left select {
  appearance: none;
  font-size: 18px;
  line-height: 27px;
  border-radius: 0.4rem;
  border: 1px solid lightgray;
  padding: 0.5rem 3rem;
  background: transparent;
}

.registration_form_dateAge-wrap > p {
  font-size: 16px;
  padding: 0.5rem 3rem 0.5rem 0rem;
  color: rgb(173, 167, 167);
  font-weight: 500;
}

/* Gender css */
/*  ============ Checked box Style Start ============ */

input[type='radio'] {
  display: none;
}

.registration_form_gender-wrap label,
.registration_form_bloodGroup-wrap label {
  display: flex;
  align-items: center;
  gap: 1rem;
}

input[type='radio'] + label span {
  width: 22px;
  height: 22px;
  border-radius: 50%;
}

input[type='radio'] + label span {
  background-color: #ebebeb;
}

input[type='radio']:checked + label span {
  background-color: var(--primary-color-2);
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
}

/*  ============ Checked box Style end ============ */

.registration_form_gender-wrap,
.registration_form_bloodGroup-wrap {
  display: flex;
  gap: 4rem;
  margin: 0.5rem 0;
}

.registration_form_gender-wrap > span,
.registration_form_bloodGroup-wrap > span {
  width: fit-content;
  font-size: 18px;
}

.registration_form_gender-wrap {
  margin-top: 1.5rem;
}

.registration_form-col {
  display: flex;
  gap: 2rem;
}

.relative-title {
  margin: -0.5rem 0 0.7rem 0;
  color: #797979;
  font-size: 16px !important;
}

.registration_form-terms {
  display: flex;
  margin: 1rem 0;
  gap: 2rem;
}

.registration_form-terms input {
  width: 25px;
  height: 25px;
  border: 1px solid var(--text-black);
}

.registration_form-button button,
.relative-title {
  font-size: 18px;
  line-height: 27px;
}

.registration_form-terms p a {
  color: var(--primary-color);
  text-decoration: revert;
}

.registration_form-button {
  display: flex;
  gap: 2rem;
  align-items: center;

  margin: 1.5rem 0 3rem 0;
}

.registration_form-button button {
  cursor: pointer;
  margin-right: 2rem;
  padding: 0.5rem 5rem;
  border-radius: 0.4rem;
  border: 1px solid gray;
  transition: all 0.3s ease-in;
}

.registration_form-button button:nth-child(1) {
  background: none;
}
.registration_form-button button:nth-child(2) {
  font-weight: 600;
  color: var(--text-white);
  background: var(--primary-color);
}

.registration_form-button button:nth-child(1):hover {
  color: var(--text-white);
  background: red;
}
.registration_form-button button:nth-child(2):hover {
  background: var(--secondary-color);
}

/* Input Type file Css  registration_form-Image=======*/
.registration_form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}
.registration_form-image {
  margin-bottom: 0.5rem;
  flex: 1;
}
.registration_form-image h5 {
  font-size: 18px;
  font-weight: 400;
  line-height: 17px;
  margin-bottom: 1rem;
}

.registration_form-image > span {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
  border-radius: 0.4rem;
  border: 1px solid lightgray;
}

.registration_form-image span > input {
  display: none;
}

.registration_form-image > span > label {
  color: var(--text-white);
  padding: 0.5rem 2.5rem;
  cursor: pointer;
  background: #8a8a8a;
}
.registration_form-image > span > label:hover {
  background: var(--primary-color);
  transition: all 0.3s ease-in-out;
}

.registration_form-image span span {
  flex: 1;
  display: block;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.registration_form-image span span label {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0.5rem 2rem;
}
.registration_form-button button:disabled {
  cursor: no-drop;
}
.registration_form-button button:disabled:hover {
  color: var(--text-white);
  background: var(--primary-color);
}

.form_grid-two {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 1rem;
}

.reg_divider {
  border: 0.5px solid rgba(207, 204, 204, 0.368);
  margin: 0.5rem 0;
}
/* =========Responive Css========= */

@media screen and (max-width: 1600px) and (min-width: 1025px) {
  .registration_form_dateAge_wrap-left {
    flex-wrap: wrap;
    gap: 1rem;
  }

  .registration_form_bloodGroup-wrap {
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
  }

  .registration_form-col {
    flex-direction: column;
  }

  .registration_form-image > span > label,
  .registration_form-image span span label {
    padding: 1rem;
  }

  .registration_form-button {
    justify-content: space-evenly;
    gap: 0;
  }
}

/* ====Form Grid CSS==== */

.form_grid-one {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2.5rem;
}

@media screen and (max-width: 1025px) and (min-width: 769px) {
  .registration-header {
    width: 100%;
    padding: 2rem;
    padding: 1rem 3rem !important;
  }

  .registration_form_wrap form {
    padding: 0 1rem;
  }

  .registration_form_dateAge-wrap > p {
    display: none;
  }

  .registration_form_dateAge_wrap-left {
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
  }

  .registration_form_bloodGroup-wrap {
    flex-wrap: wrap;
    gap: 1.5rem;
  }

  .registration_form-col {
    flex-direction: column;
  }

  .registration_form-image > span > label,
  .registration_form-image span span label {
    padding: 1rem;
  }

  .registration_form-button {
    justify-content: space-evenly;
    gap: 0;
  }
}

@media screen and (max-width: 1025px) and (min-width: 481px) {
  .registration_form_gender-wrap {
    gap: 1.5rem !important;
    margin-top: 0.8rem;
  }
}

@media screen and (max-width: 769px) {
  .registration-header {
    width: 100%;
    height: fit-content;
    padding: 1rem 1.5rem;
  }
  .registration-header h2 {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .registration_form_wrap {
    width: auto;
    margin: 0rem;
    box-shadow: none;
  }

  .registration_form_wrap form {
    padding: 0 0.5rem;
  }
  .registration_form-col {
    gap: 0;
  }
  .registration_form_dateAge-wrap > p {
    display: none;
  }

  .registration_form_dateAge_wrap-left {
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
  }

  .registration_form_gender-wrap {
    flex-wrap: wrap;
    gap: 1rem;
  }
  .registration_form_bloodGroup-wrap {
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
  }

  .registration_form-col {
    flex-direction: column;
  }

  .registration_form-image > span > label,
  .registration_form-image span span label {
    padding: 1rem;
  }

  .registration_form-button {
    justify-content: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }
  input[type='radio'] + label span {
    height: 16px;
    width: 16px;
  }
  label {
    font-size: 1rem;
  }
  .registration_form_wrap {
    padding: 1rem 1rem 0rem 1rem;
  }
  .registration_form-image > span > label,
  .registration_form-image span span label {
    padding: 0.5rem;
  }
  .registration_form_dateAge_wrap-left select {
    padding: 0rem 1rem;
  }
  .registration_form-terms {
    gap: 1rem;
  }

  .form_grid-one {
    grid-template-columns: 1fr;
  }
}

.featured_conatiner{
    background: url('../../../public/assets/Rectangle 166.png');
    background-size: cover;
    margin-bottom: 5rem;
}

.featured_conatiner_shadow{
    background-color:rgba(0, 0, 0,.5);

}
.featured_content-box{
    display: flex;
    align-items: center;
    max-width: 80%;
    /* padding: 2rem; */
    margin: 0 auto;
    height: 550px;
}
.featured_content-left{
    flex: 1;
    color: var(--text-white);
}
.featured_content-left h3{
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    margin-bottom: 2rem;
}

.featured_content-left >p{
    width: 75%;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 1.5rem;

}

.featured_content-left > button{
    color: var(--text-white);
    border: 1px solid var(--text-white);
    background: none;
    cursor: pointer;

    font-size: 16px;
    border-radius: .3rem;
    padding: .7rem 1.5rem;
}
.featured_content-left > button:hover{
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
}



.featured_content-right{
    flex: 1;
}

.featured_content_right-video{
    position: relative;
    height: 100%;
    width: 85%;
    max-width: 600px;
}
.featured_content_right-video video{
    width: 100%;
    height: 100%;
    border-radius: .5rem;
    box-shadow:  0 0 .2rem  rgba(0, 0, 0,0.5);
}


/* video controls */
.video_control{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 75px;
    height: 75px;
    display: block;
    font-size: 6rem;
    color: var(--text-white);
    cursor: pointer;
    transform: translate(-50%,-50%);
    border-radius: 50%;
    animation: scale-up-center 1s cubic-bezier(0.680, -0.550, 0.265, 1.550) both;
}
.video_control > img{
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    transform: translateY(-75%) translateX(-50%);
}
.video_control:before {
    content: "";
    position: absolute;
    z-index: 0;
    display: block;
    width: 80px;
    height: 80px;
    background: #fff;
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
  }

@keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes pulse-border {
    0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
    }
    100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
      opacity: 0;
    }
  }
  
.Pause{
    opacity: 0;
    transition: all .5s ease-in-out;
    transform: translateY(-50%) translateX(-50%) !important;
}

.video-container:hover ~ .Pause,.Pause:hover{
    opacity: 1;
}

.video_control:hover{
    color: var(--primary-color-2);
}



/* video plase  */


/* ===========Responive============= */

@media only screen and (max-width:1300px) and (min-width:1201px){
    .featured_content-box{
        max-width: 1300px;
        padding: 3rem;
    }
    .featured_content_right-video {
        width: 95%;
    }
}
@media only screen and (max-width:1200px) and (min-width:1025px){
    .featured_conatiner{
        margin-bottom: 0;
    }
    .featured_content-box{
        height: fit-content;
        padding:3rem 3rem;
        gap: 1.5rem;
        max-width: 100%;
    }
    .featured_content-left >p{
        width: 100%;
    }
    .featured_content_right-video{
        width: 100%;
    }
}

@media only screen and (max-width:1025px) and (min-width:769px){
    .featured_conatiner{
        margin-bottom: 0;
    }
    .featured_content-box{
        height: fit-content;
        padding:2rem;
        gap: 1.5rem;
        max-width: 100%;
    }
    .featured_content-left >p{
        width: 100%;
    }
    .featured_content_right-video{
        max-width: 100%;
    }
}


@media only screen and (max-width:769px){
    .featured_conatiner{
        height: auto;
        margin-bottom: 0;
    }
    .featured_content-box{
        flex-direction: column;
        justify-content: center;
        gap: 3rem;
        text-align: center;
        max-width: 100%;
        padding: 2rem;
        height: fit-content;
    }

    .featured_content-left >p{
        width: 100%;
    }


    .featured_content_right-video{
        width: 100%;
    }

    .featured_content-left h3{
        font-size: 1.5rem;
        margin-bottom:.5rem ;
    }
}

.footerPoster_container{
    width: 80%;
    max-width: 1250px;
    height: 190px;
    margin: 5rem auto -4rem auto;
    background-color: var(--primary-color);
    position: relative;
    z-index: 0;
}

.footerPoster_liner{
    background-image: url('../../../public/assets/Vector7.png');
    background-repeat:no-repeat ;

    width: 195px;
    height: 90%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
   
   position: absolute;
   right: 0;
   bottom: 0;
   z-index: 0;
}

/* Footer Poster Content  */
.footerPoster_content-container {
    position: relative;
    width: 85%;
    height: 100%;
    margin: 0 auto;
    color: var(--text-white);
    z-index: 99;

    display: flex;
    justify-content: space-between;
    align-items: center;
}


.footerPoster_content-left h4{
    font-size: 22px;
    font-weight: 500;
    line-height: 27px;
}

.footerPoster_content-left p{
    font-size: 1rem;
    line-height: 24px;
    width: 65%;
    margin-top: 2%;
}

.footerPoster_content_right-top h5,
.footerPoster_content_right-bottom h5{
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
}

.footerPoster_content_right-top,
.footerPoster_content_right-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
}

/* ===========Responive============= */


@media only screen and (max-width:1025px){
    .footerPoster_container{
        width: 100%;
        height: fit-content;
        padding: 2rem;
        margin-bottom: 0;
       
    }
    .footerPoster_liner{
        display: none;
    }

    .footerPoster_content-container{
        flex-direction: column;
        justify-content: flex-start;
        gap: 2rem;
        width: 100%;
    }

    .footerPoster_content-left p{
        width: 100%;
    }

    .footerPoster_content_right-top h5,
.footerPoster_content_right-bottom h5{
    font-weight: 600;
}

.footerPoster_content_right-bottom h5{
    margin-top: 0;
}
}

@media only screen and (max-width:768px){
    .footerPoster_container{
        margin-top: 1.5rem;
    }

    .footerPoster_content_right-bottom h5{
        margin-top: 0;
    }

}


@media only screen and (max-width:580px){
    .footerPoster_container{
        padding-bottom: 1rem;
    }
    .footerPoster_content-container{
        gap: 1rem;
    }
    .footerPoster_content_right-top > h5,.footerPoster_content_right-bottom > h5{
        margin-top: 0;
        width: 100%;
        display: block;
    }
    .footerPoster_content_right-top,
    .footerPoster_content_right-bottom{
        flex-wrap: wrap;
        gap: 0rem;
        justify-content: center;
        text-align: center;
    }
}


@media only screen and (max-width:380px){
    .footerPoster_content_right-top,
.footerPoster_content_right-bottom{
    gap: 0rem;
}
.footerPoster_container{
    margin-top: 1.5rem;
}

.footerPoster_content-left h4{
    font-size: 18px;
    font-weight: 700;
}

.footerPoster_content-container{
    gap: 1rem;
}


}







.ease_steps > h3 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 39.1px;
  width: fit-content;
  text-align: center;

  margin: 0 auto;
  padding: 0.5rem 3rem;
  background: linear-gradient(#2abfc4 50%, transparent 50%);
}

/* .ease_steps-container{
    width: 70%;
    max-width: 100%;
    margin: 3rem auto 0 auto;

    display: flex;
    justify-content: center;
    align-items: center;

}

.ease_steps_container-left{
    flex: .5;
    text-align: center;
}
.ease_steps_container-left img{
    width: 256px;
    max-width: 100%;
    height: fit-content;
    max-height: 100%;
}

.ease_steps_container-right{
    flex: .5;
}


.ease_steps_container_right_wrap{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;

    padding-bottom: 3rem;
}

.ease_steps_container_right_wrap:nth-child(3){
    padding-bottom:0;
}

.steps_content_round h1 {
    width: 88px;
    height: 88px;
    color: var(--text-white);
    border-radius: 50%;
    background-color: var(--primary-color-2);

    font-size: 2rem;
    font-weight: 900;
    font-family: 'Poppins', sans-serif !important;
    line-height: 48px;

    display: flex;
    justify-content: center;
    align-items: center;
}


.ease_steps_container_right_content h4{
    font-size: 22px;
    font-weight: 500;
    line-height: 39.01px;
}

.ease_steps_container_right_content p{
    font-size: 16px;
    line-height: 26.82px;
    
} */

/* ===========Responive============== */
/* @media screen and (max-width:1600px) and (min-width:769px) {
        
    .steps_content_round h1{
        height: 70px;
        width: 70px;
    }
    .ease_steps-container{
        width: 100%;
        padding: 2rem;
        margin-top:1.4rem;
    }
    .ease_steps_container_right_wrap{
        gap: 1rem;
    }

    .ease_steps_container-left img{
       
    }
}

@media screen and (max-width:769px) {
    .ease_steps > h3{
        font-size: 1.08rem;
        padding: .6rem;
        font-weight: bold;
    }

    .ease_steps-container{
        flex-direction: column;
        gap: 2rem;
        margin-top: 0rem;
        width: 92%;

    }

    .steps_content_round h1{
        height: 70px;
        width: 70px;
    }

    .ease_steps_container_right_wrap{
        gap: .5rem;
        align-items: flex-start;
        padding-bottom: 0rem;
    }

    .ease_steps_container-left img{
        width: 100%;
    }

    .ease_steps_container-left{
        display: none;
    }

    .steps_content_round h1{
        margin-top: 1.2rem;
        width: 2rem;
        height: 2rem;
        font-size: 1rem;
    }
    .ease_steps_container_right_content h4{
        font-size: 1.1rem;
    }
} */

.steps_bar {
  width: 70%;
  min-width: 600px;
  max-width: 100%;
  margin: 1rem auto;
  display: flex;
  border-bottom: 1px solid rgba(211, 211, 211, 0.5);
}

.steps_bar > button {
  flex: 1;
  border: 0;
  font-size: 24px;
  font-weight: 500;
  outline: none;
  padding: 1rem 0;
  background-color: transparent;
  cursor: pointer;
}

.steps_bar > button:hover {
  background-color: rgba(42, 191, 196, 0.1);
}

.steps_content {
  width: 70%;
  min-width: 600px;
  max-width: 100%;
  height: 600px;
  max-height: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.single_content {
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  background: white;
  opacity: 0;
  transition: all 0.3s ease;
}

.img {
  height: 100%;
  flex: 0.4;
  position: relative;
  display: flex;
  justify-content: center;
}

.img > img {
  width: 300px;
  height: 100%;
  border: 2px solid rgba(128, 128, 128, 0.5);
  border-radius: 8px;
}

.single_content > .content {
  flex: 0.6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding-bottom: 10%;
}

.single_content > .content h4 {
  font-size: 20px;
  font-weight: 500;
}
.single_content > .content h3 {
  font-size: 24px;
  font-weight: 500;
}
.single_content > .content p {
  font-size: 16px;
  font-weight: 400;
  text-align: justify;
  padding-right: 10px;
}
.single_content > .content > a {
  font-size: 20px;
  font-weight: 500;
  font-style: italic;
  text-decoration: underline;
  transition: all 0.4s ease;
  width: fit-content;
  margin-top: 5px;
}

.single_content > .content > a:hover {
  color: #f1634c;
}

.single_content_anime {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11111 !important;
  animation: anima 0.5s linear;
  /* transform: translateY(30%); */
  opacity: 1 !important;
}

@keyframes anima {
  0% {
    transform: translateY(20%);
    opacity: 0.5;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.nurseDashboard {
  height: 100%;
  min-height: 100vh;
  max-height: 100%;
  width: 100vw;
  max-width: 100%;
  padding: 0 1rem;
  background: rgb(148, 230, 233);
  background: linear-gradient(
    194deg,
    rgba(148, 230, 233, 0.36842683850884106) 0%,
    rgba(238, 174, 202, 0.33761451357886907) 98%
  );
}

.nurseDashboard_wrap {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr 10fr 1fr;
  grid-template-areas: 'left left content content content content content content right right';
}

.nurseDashboard_grid_top {
  padding-top: 10px;
  max-height: 100%;
  border-bottom: 1px solid rgba(211, 211, 211, 0.356);
  background-color: #2abfc4;
}

.nurseDashboard_grid_left {
  grid-area: left;
  height: 100%;
  min-width: 300px;
  max-width: 100%;
  padding: 0.8rem 0.8rem 0.8rem 0rem;
}

.nurseDashboard_grid_right {
  grid-area: right;

  width: 350px;
  min-width: 300px;
  max-width: 100%;
}

.nurseDashboard_grid_body {
  grid-area: content;
  padding: 0.8rem;
}

.mainListCard:hover .innderHover {
  color: rgb(0, 195, 255);
  transform: scale(1.2);
  margin-right: 10px;
}

.nurseDashboard_grid_body_wrap {
  /* display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: start;
  width: fit-content; */
  /* padding: 1rem; */
  /* gap: 1rem; */
  /* background-color: white; */
  border-radius: 10px;
  min-height: 87vh;
}

@media screen and (max-width: 900px) {
  .nurseDashboard_grid_top {
    background-color: rebeccapurple;
  }

  .nurseDashboard_wrap {
    grid-template-areas: 'left left content content content content content content';
  }

  .nurseDashboard_grid_right {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .nurseDashboard_grid_top {
    background-color: rgb(132, 238, 44);
  }

  .nurseDashboard_wrap {
    grid-template-areas: 'content content content content content content';
  }

  /* .nurseDashboard_grid_right,
  .nurseDashboard_grid_left {
    display: none;
  } */
}

.btn-linearGradient {
  background: rgb(148, 187, 233);
  background: linear-gradient(
    90deg,
    rgba(238, 174, 202, 0.5) 0%,
    rgba(148, 187, 233, 0.5) 100%
  );
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  color: black;
  font-weight: 600;
}
.btn-inner-hover > li a:hover {
  background: linear-gradient(
    90deg,
    rgba(148, 187, 233, 0.5) 0%,
    rgba(238, 174, 202, 0.5) 100%
  );
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.best-hospital {
    padding: 40px 0 60px;
  }
  .hospital-cards {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
  
  /* @media only screen and (max-width:1300) and (min-width:1201px){
  
  } */
  
  @media only screen and (max-width: 1200px) and (min-width: 1025px) {
  }
  
  @media only screen and (max-width: 1025px) and (min-width: 769px) {
    .hospital-cards {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media only screen and (max-width: 769px) {
    .hospital-cards {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media only screen and (max-width: 470px) {
    .hospital-cards {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  
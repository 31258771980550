.doctor_profile_view_wrap{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--text-white);

}

.doctor_close_icon {
        color: var(--text-white);
        font-size: 3rem;
        border-radius: .3rem;
        background-color: rgba(255, 0, 0, 0.542);
        cursor: pointer;
        display: block;
        position: absolute;
        right: 3%;
        top: 2%;
    }


.doctor_close_icon:hover{
    background-color: red;
    color: var(--text-black);
}

/* Add Doctor Form list  css*/
.alldoctor_form_wrap{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--text-white);
    padding-bottom: 4rem;
}

.alldoctor_form_wrap h3{
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.alldoctor_form_wrap form{
    border: 1px solid lightgray;
    box-shadow: 0 0 .3rem lightgray;
    border-radius: .5rem;
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem;
    background-color: #fff;
    
}
.alldoctor_form_wrap form > span{
    display: flex;
    flex-direction: column;
    gap: .75rem;
}

.alldoctor_form_wrap form > span> label{
    font-size: 1.25;
}
.alldoctor_form_wrap form > span> label>span{
   color: red;
}

.alldoctor_form_wrap form > span>input{
    height: 56px;
    padding: .5rem 1rem;
    font-size: 1.3rem;
    border-radius: .5rem;
    outline: 0;
    border: 1px solid lightgray;
}

.alldoctor_form_wrap form > span>input:focus{
    box-shadow: 0 0  .2rem lightgray;
}
.alldoctor_form_wrap form > span>p{
    color: red;
    padding-left: 1rem;
    margin-top: -.2rem;
}

.alldoctor_form_wrap form >button{
    align-self: center;
    font-size: 1.5rem;
    font-weight: 500;
    padding: .8rem 10%;
    border-radius: .4rem;
    border: 0;
    background-color: var(--primary-color);
    color: var(--text-white);
    cursor: pointer;
    margin-top: 1rem;
    margin-bottom: 2rem;
    transition: all .2s ease-in;
}
.alldoctor_form_wrap form >button:hover{
    background-color: var(--primary-color-2);
}

.error_box{
    border: 1px solid red !important;
}

.confirm_doctor_delete{
    text-align: center;
    border: 0;
    padding: .5rem 2rem;
    font-size: 1.5rem;
    background-color: rgba(255, 0, 0, 0.572);
    position: absolute;
    top: 2.5%;
    left: 50%;

    border-radius: .4rem;
    font-weight: 500;
    color: var(--text-white);
    cursor: pointer;
}

.confirm_doctor_delete:hover{
    background-color: red;
    box-shadow: 0 0 .3rem red;
}
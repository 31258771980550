/* Container to hide the default scrollbar */
.custom-scrollbar-container {
    overflow: hidden;
  }
  
  /* Custom style for the scrollbar */
  .custom-scrollbar-container::-webkit-scrollbar {
    width: 22px;
  }
  
  .custom-scrollbar-container::-webkit-scrollbar-thumb {
    background: linear-gradient(135deg, #ff6b6b 0%, #c73838 100%);
    border-radius: 8px;
  }
  
  .custom-scrollbar-container::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 8px;
  }
  
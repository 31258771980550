.dashboard_right_title{
    position: relative;
}

.message_replay_wrap{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    min-height: 80vh;
    width: 100%;
    background-color: rgba(26, 16, 47, 0.393);

    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    color: aliceblue;
}

/* .action_icon3{

} */

.message_replay_wrap form{
    display: flex;
    flex-direction: column;
    gap:1rem ;
}
.message_replay_wrap h3{
    font-size: 2rem;
}


.message_replay_wrap form label{
    font-size: 1.5rem;
    font-weight: 500;
}
.message_replay_wrap textarea{
    height: 50%;
    min-height: 400px;
    width: 50%;
    min-width: 700px;
    padding: 1rem;
    font-size: 1.3rem;
    border: 0;
    outline: 0;
    box-shadow: 0 0 .3rem lightgrey;
    border-radius: .4rem;
}


.message_replay_wrap button{
    padding: .7rem 2rem;
    font-size: 1.4rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    cursor: pointer;

    border: 0;
    background-color: rgba(72, 219, 251,1.0);
    border-radius: .4rem;
    letter-spacing: 1px;
}

.message_replay_wrap button:hover{
    background-color:rgba(84, 160, 255,1.0) ;
    font-weight: 600;
    color: var(--text-white);
    box-shadow: 0 0 .3rem var(--primary-color-2);
}

.message_replay_subHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.replay_closeIcon{
    color: var(--text-white);
    font-size: 3rem;
    border-radius: .3rem;
    background-color: rgba(255, 0, 0, 0.542);
    cursor: pointer;
}

.replay_closeIcon:hover{
    background-color: red;
    color: var(--text-black);
}

.view_contact_container{
    position: absolute;
    top: 0;
    width: 100%;
    height: 80vh;
    background-color: rgba(94, 96, 90,.3);
    display: flex;
    align-items: center;
    justify-content: center;
}

.view_contact_wrap{
    background-color: gray;
    border-radius: .5rem;
    width: 60%;
    height: fit-content;
    min-height: 600px;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;  
    color: var(--text-white);
}

.posttion{
    position: absolute;
    top: 1rem;
    right: 2rem;
}


.view_contact_wrap h3{
    font-size: 1.5rem;
}

.view_contact_wrap p{
    font-size: 1.3rem;
    font-weight: 500;
}
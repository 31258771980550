.bunner-container {
  background-color: #e7fcff;
}
.main-bunner {
  width: 80%;
  display: flex;
  align-items: center;
  padding: 60px 0;
}
.bunner-text,
.bunner-img {
  flex: 1;
}
.bunner-text h2 {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.07;
  color: #262626;
  margin: 50px 0 10px 0;
}
.bunner-text h4 {
  font-size: 36px;
  font-weight: 500;
  line-height: 1.07;
  color: #262626;
  margin-bottom: 30px;
}
.bunner-text p {
  margin-bottom: 10px;
}
.bunner-text p span {
  font-weight: 600;
  color: #414141;
}
.bunner-text p .icon {
  color: #0a9768;
}
.bunner-text button {
  margin-top: 30px;
  padding: 10px 25px;
  color: #fff;
  font-weight: 700;
  min-width: 150px;
  border-radius: 5px;
  background: linear-gradient(90deg, #f55912 0, #f76b1c),
    linear-gradient(180deg, #fff, #fff);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.bunner-img {
  display: flex;
}
.slider-content{
  display: flex;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.slider-text,
.slider-img {
  flex: 1;
}
.slider-text {
  background-color: #fff;
}
.slider-text .slider-info {
  padding: 20px 30px;
}
.slider-text .slider-benifit {
  font-size: 12px;
  font-weight: 800;
  line-height: 2.67;
  color: #00c0d8;
}
.slider-text .slider-head {
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 1rem;
}
.slider-text .slider-head span {
  display: block;
  font-weight: normal;
}

.slider-text .slider-describ {
  font-style: 12px;
  margin-bottom: 24px;
}
/* .slider-img {
  background-image: url("https://gomedii.com/mt/assets/images/slidee_1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
} */
.slider-img img{
  width: 100%;
  height: 100%;
}

/* Services Part */
.services {
  width: 80%;
  display: flex;
  padding-top: 35px;
  border-bottom: 1px solid #aab4c1;
}

/* Responsive */
@media only screen and (max-width: 1920px) and (min-width: 1301px) {
  .services {
    margin: 0 auto;
  }
}
@media only screen and (max-width: 1300px) and (min-width: 1201px) {
  .main-bunner {
    width: 100%;
    padding: 60px 48px;
  }
  .services {
    width: 100%;
    padding: 0 32px;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 1025px) {
  .main-bunner {
    width: 100%;
    padding: 60px 48px;
  }
  .services {
    width: 100%;
    padding: 35px 32px 0;
  }
}
@media only screen and (max-width: 1025px) and (min-width: 769px) {
  .main-bunner {
    width: 100%;
    padding: 60px 32px;
    flex-direction: column-reverse;
  }
  .bunner-img {
    width: 100%;
    height: 400px;
    margin-top: 20px;
  }
  
}
@media only screen and (max-width: 768px) and (min-width: 320px) {
  .main-bunner {
    flex-direction: column;
  }
}

.services-item {
  flex: 1;
}

.services-item {
  display: flex;
  justify-content: center;
  align-items: end;
  margin-bottom: 40px;
  padding: 10px;
  transition: all 0.5s;
}
.services-item:hover {
  box-shadow: 0 2px 15px 0 rgba(14, 122, 133, 0.5);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}
.services-img img {
  max-width: 40px;
  margin-right: 12px;
}
.services-text h4 {
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 10px;
}
.services-text p {
  font-size: 10px;
}
.services-text p span {
  display: block;
}

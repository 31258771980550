.hospital-item {
    padding: 8px 5px;
    color: #555049;
  }
  .hospital-img {
    /* background-image: url("https://i.ibb.co/QbyNcp0/Max.jpg"); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 140px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .hospital-item .hospital-info h3 {
    font-size: 14px;
    font-weight: 700;
    margin: 10px 0;
  }
  .hospital-item .hospital-info p {
    font-size: 12px;
    margin-bottom: 12;
  }
  
  @media only screen and (max-width: 1025px) and (min-width: 769px) {
   
  }
  @media only screen and (max-width: 769px) {
    .hospital-img {
      /* background-image: url("https://i.ibb.co/QbyNcp0/Max.jpg"); */
      background-position: top;
      height: 200px;
    }
  }
  
  @media only screen and (max-width: 470px) {
    
  }
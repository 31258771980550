.counter-container{
    background-color: var(--primary-color-2);
    margin:-2rem 0;
}

.counter-content{
    width: 80%;
    max-width: 1350px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(5,1fr);
    padding: 1rem 0;
}

.counter_card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: .2rem;
    padding: 1rem;
    border-left: .3px solid rgba(245, 246, 250,.3);
    border-top: .3px solid rgba(245, 246, 250,.3);
}

.counter_card:nth-child(1),
.counter_card:nth-child(2),
.counter_card:nth-child(3),
.counter_card:nth-child(4),
.counter_card:nth-child(5){
    border-top: 0; 
}

.counter_card:nth-child(1),.counter_card:nth-child(6){
    border-left:0 ;
}

.counter_card> img{
    max-width: 50px;
    margin-bottom: 15px;
}
.counter_card> h3{
    font-size: 26px;
    color: var(--text-white);
    line-height: 30px;
    font-weight: 500;
}

.counter_card >h4{
    color: var(--text-white);
    font-size: 18px;
    font-weight: 500;
    line-height: 26.5px;
}

/* ===========Responive============= */
@media only screen and (max-width:1201px) and (min-width:1025px){
    .counter-content{
        width: 100%;
        padding: 3rem;}
    }
    



@media only screen and (max-width:1025px) and (min-width:769px){
    .counter-content{
        width: 100%;
        padding: 2rem;
        grid-template-columns: repeat(5,1fr);
    }

    .counter_card> h3{
        font-size: 1.5rem;
        line-height: inherit;
    }
    .counter_card >h4{
        font-size: 1rem;
    }
    .counter_card{
        padding: .5rem;
    }
}

@media only screen and (max-width:769px){
    .counter-content{
        width: 100%;
        padding: 2rem;
        grid-template-columns: repeat(3,1fr);

    }

    .counter_card> h3{
        font-size: 1.5rem;
        line-height: inherit;
    }
    .counter_card >h4{
        font-size: 1rem;
    }
    .counter_card{
        padding: .5rem;
    }

    .counter_card{
     border-bottom:.3px solid rgba(245, 246, 250,.3);
     border-left:0;
    } 

    .counter_card:nth-child(1), .counter_card:nth-child(2), .counter_card:nth-child(4), .counter_card:nth-child(5), .counter_card:nth-child(7), .counter_card:nth-child(8) {
        border-right: 1px solid rgba(245, 246, 250,.3);
    }

    .counter_card:nth-child(10){
        border-bottom: 0;
    }

}


@media only screen and (max-width:481px){
        .counter-content{
            width: 100%;
            padding: 2rem;
            grid-template-columns: repeat(2,1fr);
        }
    
        .counter_card{
         border-bottom:.3px solid rgba(245, 246, 250,.3);
         border-left:0 ;
        } 

        .counter_card> h3{
            font-size: 1.5rem;
            line-height: inherit;
        }
        .counter_card >h4{
            font-size: 14px;
        }
        .counter_card{
            padding: .5rem;
        }
        .counter_card:nth-child(9){
            border-bottom: 0;
        }
        .counter_card:nth-child(2), .counter_card:nth-child(4), .counter_card:nth-child(6), .counter_card:nth-child(8), .counter_card:nth-child(10){
            border-right: 0;
        }
        .counter_card:nth-child(3), .counter_card:nth-child(9){
            border-right: 1px solid rgba(245, 246, 250,.3);
        }
    
    }
   
.doctors_Nav{
    width: 80%;
    margin: 1rem auto 0 auto;

    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    align-items: center;
}

.doctors_Nav-menu{
    flex: 1;
    font-size: 22px;
    font-weight: 500;
    line-height: 27px;
}

.doctors_Nav-menu a{
    margin-right: 3rem;
    line-height: 2.5rem;
}
.dmActive{
    padding-bottom: .3rem;
    border-bottom: 2px solid var(--primary-color-2);
}

.doctors_Nav-search{
    display: flex;
    justify-content: flex-end;

    overflow: hidden;
    border-radius: .4rem;
    border: 1px solid lightgray;
    
}
.doctors_Nav-search input{
    font-size: 16px;
    padding: .5rem .7rem;

    border: 0;
    outline: 0;
}

.doctors_Nav-search button{
    font-size: 22px;
    color: var(--text-white);
    background: var(--primary-color);
    border: 0;
    padding: 0 1rem;
    cursor: pointer;
    
    display: flex;
    align-items: center;
    justify-content: center;
}

.doctors_Nav-search button:hover{
    background: var(--secondary-color);
}



@media screen and (max-width:769px) {
    .doctors_Nav{
        width: 100%;
        padding: 2rem;
    }
    .doctors_Nav-menu{
        flex: auto;
        width: fit-content;
    }
    
    .doctors_Nav-menu a{
        margin-right: 2rem;
    }
}


/* //=========== Doctor Card====== */
.ourDoctors_container{
    margin: 0 auto;
    text-align: center;
    height: fit-content;
    min-height: 600px;
    max-width: 1515px;
}

.ourDoctors_container> h4{
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 26.82px;
    color: var(--primary-color-2);

}
.ourDoctors_container >h2{
    font-size: 2.6rem;
    line-height: 62px;
    margin-top: 2rem;
}

/* doctorPortfolio_card Css */

.ourDoctors_portfolio{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 4rem 0;
}

.doctorPortfolio_card{
    background: rgb(88, 88, 88);
    height: 566px;
    width: 365px;
    max-height: 100%;
    position: relative;
    border-radius: .2rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.doctorPortfolio_card >img{
    height:  382px;
    width: 100%;
    
}

.doctorPortfolio_card-shape {
    width: 100%;
    height: 270px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: .5rem;
}

.shape-left{
    background-image: url('../../../public/assets/Vector 5.png');
}
.shape-right{
    background-image: url('../../../public/assets/Vector 6.png');
}

.doctorPortfolio_card-shape>h5{
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color: var(--primary-color-2);
}
.doctorPortfolio_card-shape>h4{
    font-size: 22px;
    padding: 0 .5rem 0 1rem;
    font-weight: 500;
    line-height: 26px;
    text-align: center;
}
.doctorPortfolio_card-deg{
    color: var(--primary-color-2);
}


.doctorPortfolio_card-media{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
.doctorPortfolio_card-media a{
    height: 2rem;
    width: 2rem;
    font-size: 1.2rem;
    color: var(--text-white);
    background-color: var(--primary-color);
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
}
.doctorPortfolio_card-media a:hover{
    color: var(--primary-color-2);
}



.details-btn_wrap
{
    background-color: #fff;
    outline: none;
    border: 0;
    width: 100%;
    height:100%;
    max-height:50px;
    color: var(--primary-color);
   border-top:1px solid var(--primary-color);
   display: flex;
   justify-content: space-around;
   align-items: center;
   font-weight: 500;
   cursor: pointer;
   transition: all .3s ease-in-out;
}

.details-btn_wrap span:nth-child(2)
{
    font-size: 1.2rem;
}

.details-btn_wrap:hover{
    color: var(--text-white);
    font-weight: 600;
    background-color: var(--primary-color);
}


/* ===========Responive============= */  
@media only screen and (max-width:1600px) and (min-width:1201px){
    .ourDoctors_container{
        width: 100%;
        padding: 2rem;
        margin: 2rem 0;
    }
  
}

@media only screen and (max-width:1201px) and (min-width:1025px){
    .ourDoctors_container{
        width: 100%;
        padding: 2rem;
        margin: 2rem 0;
    }
    .ourDoctors_portfolio{
        grid-template-columns: repeat(3,1fr);
       
    }
}


@media only screen and (max-width:1025px) and (min-width:769px){
    .ourDoctors_container{
        width: 100%;
        padding: 1rem;
        margin: 2rem 0;
    }
    
}

/* Doctor Not Found========== */
.doctor_not_found{
    position: relative;
    height: 500px;
    width: fit-content;
    overflow: hidden;
}

.doctor_not_found img{
    min-height: 500px;
    height: 100%;
    width: 100%;
}

.doctor_not_found p{
    font-size: 2rem;
    margin-top: -15%;
    font-weight: 500;
    color: rgb(255, 0, 119);
}
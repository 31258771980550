/* Doctor Booking wrap Css  */

.doctor_Booking_wrap{
    overflow: hidden;
 }
 .doctor_Booking_wrap .doctor_booking_header{
     margin: 1rem 0;
     
     display: flex;
     gap: .5rem;
     align-items: center;
     text-transform: capitalize;
     letter-spacing: 1px;
 
 }
 .doctor_Booking_wrap .doctor_booking_header h4{
     flex: .25;
     border-bottom: 2px solid var(--primary-color-2);
 }
 
 .doctor_booking_content_wrap{
     display: flex;
     flex-direction: column;
     gap: .5rem;
     overflow: auto;
 }
 
 .doctor_Booking_wrap .doctor_booking_content{
     display: flex;
     gap: .5rem;
     text-transform: capitalize;
     align-items: center;
     padding: .5rem .7rem;
     border-radius: .3rem;
     border-bottom: 1px solid lightgray;
 }
 
 .doctor_Booking_wrap .doctor_booking_content:hover{
     font-weight: 500;
     background-color: rgba(162, 155, 254,.1);
 }
 
 
 .doctor_Booking_wrap .doctor_booking_content p{
     flex:.25;
     font-size: 1.2rem;
 }

 .doctor_Booking_wrap .doctor_booking_content p:nth-child(4){
    display: flex;
    align-items: center;
    gap: 2rem;
 }
 .doctor_Booking_wrap .doctor_booking_content p button{
    font-size: 1.3rem;
    border: 0;
    padding: .5rem 1rem;
    border-radius: .3rem;
    cursor: pointer;
    background-color: rgba(214, 48, 49,.6);
 }

 .doctor_Booking_wrap .doctor_booking_content p button:hover{
    background-color: rgba(214, 48, 49,1);
    font-weight: 600;
 }



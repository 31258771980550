.OSC-card {
  border: 2px solid hsla(0, 0%, 83%, 0.4);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.OSC-card:hover {
  border: 2px solid rgba(42, 191, 196, 0.3);
}
.OSC-card:hover .service-image img {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}
.OSC-card > .service-image {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.OSC-card > .service-image > img {
  transition: 0.6s ease-in-out;
  width: 100%;
  height: 100%;
  -webkit-transition: 0.6s ease-in-out;
  -moz-transition: 0.6s ease-in-out;
  -ms-transition: 0.6s ease-in-out;
  -o-transition: 0.6s ease-in-out;
}

.OSC-card > .service-text > h2 {
  font-size: 20px;
  font-weight: 600;
}
.OSC-card > .service-text > p {
  font-size: 16px;
  color: var(--parragraph-color);
}
.OSC-card > .service-text > button {
  padding: 8px 16px;
  background-color: #2abfc4;
  color: var(--text-white);
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
}
.OSC-card > .service-text > button:hover {
  background-color: #f1634c;
  font-weight: 600;
}

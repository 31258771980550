.doctorDetails_wrap{
color: var(--text-black);
}

.doctorDetails_wrap > .doctorDetails_line{
    margin: 1rem 0;
}

.doctorDetails_line{
    width: 100%;
    border: 1px solid lightgray;
}

.doctorDetails_wrap_name{
    width: 80%;
    max-width: 2200px;

    margin: 0 auto;
}
.doctorDetails_wrap_name h2{
    font-size: 2.6rem;
    line-height: 4rem;
}
.doctorDetails_wrap_name p{
    font-size: 18px;
    line-height: 27px;
}

.doctorDetails_wrap_details{
    /* width: 80%; */
    max-width: 1500px;
    /* max-width: 2200px; */

    margin: 0 auto;
    
    display: flex;
    gap: 1rem;
}


.doctorDetails_wrap_details-left{
    flex: .4;
    height: 542px;
}

.doctorDetails_wrap_details-left img{
    width: 100%;
    height: 100%;
}


.doctorDetails_wrap_details-right{
    flex: .6;
    border-radius: .4rem;
    border: 1px solid lightgray;
    box-shadow: 0 0 7px 1px rgba(133, 133, 133, 0.2);
}

.doctorDetails_wrap_details-right h3{
    font-size: 2rem;
    font-weight: 500;
    line-height: 2.5rem;
    padding: 1rem 2rem;
}


.doctorDetails_wrap_details_right-content{
    height: 100%;
    min-height: fit-content;
    display: flex;
    flex-direction: column;
    
    gap: 3.5%;
    
    padding: 1.5rem 2rem;
}

.right_content-details{
    display: flex;
    justify-content: flex-start;
    gap: .5rem;
}
.right_content-details,
.right_content-details > p{
    font-size: 1.43rem;
    line-height: 26.82px;
}

.right_content-details h5{
    flex: .25;
}

.right_content-details p{
    flex: .75;
}

.doctorDetails_availability{
    width: 80%;
    max-width: 2200px;
    margin: 4rem auto 0 auto;

    border-radius: .4rem;
    border: 1px solid lightgray;
}

.doctorDetails_availability_title{
    padding: 2rem;
}

.doctorDetails_availability_title > h3{
    font-size: 2rem;
    font-weight: 500;
    line-height: 2.5rem;
    text-align: center;
    margin-bottom: 1rem;
    
}
.doctorDetails_availability_subtitle{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;

    
    font-size: 1.43rem;
    line-height: 26.82px;
}

.doctorDetails_availability_subtitle >span{
    display: flex;
    gap: 1rem;
    
    font-weight: 500;
}

.doctorDetails_availability-consultaion{
    
    padding: 2rem;
}

.doctorDetails_availability-consultaion > h3{
    font-size: 2rem;
    font-weight: 500;
    line-height: 39.01px;
    text-align: center;
    margin-bottom: 1.5rem;
}


.doctorDetails_availability_consultaion-content{
    display: flex;
    flex-wrap: wrap;
    gap:1rem;
    justify-content: center;
    color: var(--text-white);
}
.doctorDetails_availability_consultaion-content h5{
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    background-color: var(--primary-color);
    width: fit-content;
    padding: 1rem 2rem;
    border-radius: 2rem;

    margin-bottom: .5rem; /*Deleted this line*/
} 




.doctorDetails_youtube{
    width: 80%;
    height: 700px;
    display: block;
    margin: 1rem auto;
}

/* =========Responive======== */
@media only screen and (max-width:1600px) and (min-width:1025px){
    .doctorDetails_wrap_details{
        width: 97%;
    }

    .doctorDetails_wrap_details-left{
        flex: .35;
    }
    .doctorDetails_wrap_details-left img{
        height: 400px;
    }

    .doctorDetails_wrap_details-right{
        flex: .65;
        min-height: 545px;
    }

    .right_content-details h5{
        flex:.3;
    }
.right_content-details p{
    flex: .7;
}
}

@media only screen and (max-width:1025px) and (min-width:769px){
    .doctorDetails_wrap_details{
        width: 95%;
        max-width: 800px;
        

        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .doctorDetails_wrap_details_right-content{
        gap: 1.5rem;
    }

.doctorDetails_youtube{
    height: 500px;
}
    
}
@media only screen and (max-width:769px){
    .doctorDetails_wrap_name{
        width: 100%;
        padding: 2rem;
    }
    .doctorDetails_wrap_details{
        width: 100%;

        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .right_content-details{
        flex-direction: column;
    }
    .right_content-details h5,.right_content-details p{
        flex: auto;
    }

    .doctorDetails_availability{
        width: 100%;
        padding: 2rem;
        margin-top: 2rem;
    }
    .doctorDetails_availability_subtitle{
        flex-wrap: wrap;
    }

    .doctorDetails_wrap_details_right-content{
        gap: 1rem;
    }

    .doctorDetails_youtube{
        height: 400px;
        width: 95%;
    }
        

}

.postCard_container {
  height: 100%;
  min-height: 390px;
  max-width: 100%;
  border-radius: 0.8rem;
  box-shadow: 0 1px 6px rgba(119, 140, 163, 0.8);
  overflow: hidden;
  margin: 0 auto;
}

.postCard_img {
  overflow: hidden;
}

.postCard_img > img {
  width: 100%;
  transition: all 0.8s ease-in-out;
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -ms-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
}

.postCard_content {
  height: 40%;
  padding: 1.5rem;
  text-align: start;
}
.postCard_content > h4 {
  font-size: 20px;
  padding: 0 0 0.5rem 0;
  font-weight: 600;
}

.postCard_content > p {
  font-size: 16px;
  color: var(--parragraph-color);
  line-height: 24px;
  /* overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; 
  line-clamp: 2;
  -webkit-box-orient: vertical; */
}

.postCard_content > a {
  border: 0;
  font-size: 14px;
  color: var(--primary-color);
  background: none;
  margin-top: 0.5rem;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  align-items: center;
  justify-content: end;
  gap: 0.5rem;
}

.postCard_content > a:hover {
  color: var(--primary-color-2);
}

.postCard_container:hover .postCard_img > img {
  transform: scale(1.2);
}

/* ==============Responive CSS==================== */

@media only screen and (max-width: 470px) {
  .postCard_container {
    height: fit-content;
    min-height: fit-content;
  }

  .postCard_img {
    height: 50%;
  }
  .postCard_content {
    height: 50%;
  }
  .postCard_content > h4 {
    font-size: 18px;
    padding: 0 0 0.2rem 0;
  }
  .postCard_content > p {
    font-size: 14px;
  }
}

.Contact {
  width: 80%;
  /* min-width: 600px; */
  max-width: 100% !important;
  margin: 0 auto;
  border-radius: 0.5rem;
  /* box-shadow: 0 0 7px 1px rgba(133, 133, 133, 0.2); */
  overflow: hidden;
  /* display: flex; */
}

.Contact_form {
  /* flex: 0.5; */
  background-color: #00d6dd11;
  margin: 0 auto;
}

.Contact_form form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  padding: 2rem;
}

.Contact_form form label {
  color: var(--text-balck);
  font-size: 18px;
  line-height: 27px;
  padding-top: 15px;
}

.Contact_form form input,
.Contact_form form textarea {
  outline: none;
  border-radius: 0.3rem;
  font-size: 16px;
  letter-spacing: 1px;
  padding: 0.7rem;
  border: 1px solid #313030;
}

.Contact_form form input:focus,
.Contact_form form textarea:focus {
  box-shadow: 0 0 4px 0px var(--primary-color);
}

.Contact_form form textarea {
  height: 143px;
}

.Contact_form form button {
  margin: 0 auto;
  color: var(--text-white);
  background-color: var(--primary-color);
  font-size: 22px;
  margin-top: 1rem;
  border-radius: 0.4rem;
  padding: 0.5rem;
  min-width: 250px;
  max-width: 300px;
  padding-top: 3px;
  cursor: pointer;
  border: 0;
  transition: all 0.3s ease-in;
}
.Contact_form form button:hover {
  background: var(--secondary-color);
}

/* Contact_info Css====== */

.Contact_info {
  flex: 0.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  max-height: fit-content;
  color: var(--text-white);
  background-color: var(--primary-color);
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
  box-shadow: -10px 0px 00px rgb(0, 161, 167);
}

.Contact_info h2 {
  font-size: 2.6rem;
  line-height: 63px;
}

.Contact_info-1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 3rem;
}
.Contact_info-1 span {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.Contact_info-1 span a {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 26.82px;
}

.Contact_info-1 span a:hover {
  color: var(--text-black);
  text-decoration: revert;
}

.Cantact_info_1_icon {
  font-size: 2rem;
}

.Contact_info-2 {
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 1rem;
}

.Contact_info-2 a {
  width: 54px;
  height: 54px;
  color: var(--primary-color);
  font-size: 1.7rem;
  border-radius: 50%;
  background: var(--text-white);

  display: flex;
  justify-content: center;
  align-items: center;
}

.Contact_info-2 a:hover {
  color: var(--primary-color-2);
}

/* =========Responsive============ */

@media screen and (max-width: 1600px) and (min-width: 1025px) {
  .Contact_form {
    width: 60%;
  }
  .Contact {
    width: 80%;
  }
}
@media screen and (max-width: 1025px) and (min-width: 769px) {
  .Contact {
    width: 95%;
  }

  .Contact_info {
    padding: 0.5rem 0;
  }
  .Contact_info h2 {
    font-size: 2rem;
  }
  .Contact_info-1 {
    gap: 1.5rem;
  }

  .Contact_info-1 span {
    gap: 0.5rem;
  }
  .Contact_info-1 span a {
    font-size: 1.1rem;
  }
  .Cantact_info_1_icon {
    font-size: 1.5rem;
  }

  .Contact_info-2 a {
    height: 2.5rem;
    width: 2.5rem;
  }
  .Contact_form {
    width: 80%;
  }
}

@media screen and (max-width: 769px) and (min-width: 481px) {
  .Contact {
    flex-direction: column;
    width: 95%;
  }
  .Contact_info {
    padding: 0.5rem 0;
    min-height: 500px;
  }
  .Contact_info h2 {
    font-size: 2rem;
  }
  .Contact_info-1 {
    gap: 1.5rem;
    padding: 0.5rem 0 2rem 0;
  }

  .Contact_info-1 span {
    gap: 0.5rem;
  }

  .Contact_info-2 {
    padding-bottom: 1rem;
  }
}

@media screen and (max-width: 481px) {
  .Contact {
    width: 100%;
    flex-direction: column;
    height: fit-content;
    box-shadow: none;
    padding: 1rem;
  }

  .Contact_info {
    padding: 0.5rem 0;
    min-height: 400px;
  }
  .Contact_info h2 {
    font-size: 1.5rem;
  }
  .Contact_info-1 {
    gap: 1.5rem;
    padding: 0 0 1.5rem 1rem;
  }

  .Contact_info-1 span {
    gap: 0.5rem;
  }
  .Contact_info-1 span a {
    font-size: 1.1rem;
  }
  .Cantact_info_1_icon {
    font-size: 1.5rem;
  }

  .Contact_info-2 {
    padding-bottom: 1rem;
  }

  .Contact_info-2 a {
    height: 2.5rem;
    width: 2.5rem;
  }
}

.banner_content{
    background-image: url('../../../../public/assets/herobg.04dbcacc.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;

    height: 320px !important;
    min-height: 60vh;
    margin-top: -6rem;
    width: 100%;
    max-width: 100vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    color: var(--text-white);
    padding: .8rem;
}

.banner_content h1{
    font-size: 4rem;
    font-weight: 700;
    line-height: 85px;
}
.banner_content h4{
    font-size: 22px;
    font-weight: 400;
    line-height: 26.86px;
    text-align: center;
    width: 35%;
}



/* =====Responsive=========== */

@media only screen and (max-width:1600px) and (min-width:1201px){
   
    .banner_content h4{
        width: 100%;
    }
}
@media only screen and (max-width:1201px) and (min-width:1025px){
    .banner_content{
        height: 500px;
        max-height:100% ;
    }
    .banner_content h1{
        font-size: 3rem;
        font-weight: 500;
        line-height: 3rem;
    }
    .banner_content h4{
    width: 100%;
    }
}
@media only screen and (max-width:1025px) and (min-width:769px){
    .banner_content{
        padding: 0 2rem;
    }
    .banner_content h1{
        font-weight: 500;
        font-size: 2.2rem;
        line-height: 2.2rem;
        text-align: center;
    }
    .banner_content h4{
        line-height: 1.7rem;
    width: 100%;
    }
}
@media only screen and (max-width:769px) and (min-width:481px){
    .banner_content{
        min-height: 100%;
        text-align: center;
    }
    .banner_content h1{
        font-size: 1.7rem;
        font-weight: 500;
        line-height: 2.2rem;
    }
    .banner_content h4{
        font-size: 18px;
    width: 100%;
    margin-bottom: 0;
    }
}
@media only screen and (max-width:481px){
    .banner_content{
        height: 45vh;
        min-height: 100%;
        text-align: center;
    }
    .banner_content h1{
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1.5rem;
    }
    .banner_content h4{
    font-size: 1rem;
    width: 100%;
    margin-bottom: 0;
    }
}
    
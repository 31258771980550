.users_register{
    margin-top: 1rem;
    overflow: hidden;
    
    display: flex;
    flex-direction: column;

}

.users_register_content-wrap{
    height:78vh;
    overflow:auto;
}

.users_register-content{
    overflow-y: auto;
}


.users_register-header,.users_register-content{
    display: flex;
    padding: 0 1rem;

}
.users_register-header h3{
    margin-bottom: 1rem;
    color: gray;
}

.users_register-header h3,.users_register-content p{
    flex: .33333333333;
}


.users_register-content{
    font-weight: 500;
    font-size: 1.125rem;
    align-items: center;
    color: var(--text-black);
    margin-top: .8rem;
    border-bottom: 1px solid lightgray;
}

.users_register-content:hover{
    background-color: rgba(162, 155, 254, 0.055);
}


.users_register-content p:nth-child(1){
    display: flex;
    align-items: center;
    gap: .7rem;
}

.users_register-content p>img{
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 50%;
}

.users_register-content > p a:hover{
    color: var(--primary-color-2);
    text-decoration: revert;
}
footer{
    background-color: var(--primary-color-2);
    padding-top: 8rem;
}

.footer_info_container{
    width: 100%;
    margin: 0 auto;
    color: var(--text-white);
    padding: 0 3rem;
    display: grid;
    grid-template-columns: repeat(4,1fr);
}

.footer_info_content-1{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: .5rem;
}

.footer_info_content-1 img{
    width: 170px;
    margin-left: -1rem;
  
}

.footer_info_content-1 > p{
    font-size: 16px;
    line-height: 24px;
    width: 70%;
}
.footer_info_social p{ 
    font-size: 16px;
    font-weight: 600;
    font-family: var(--sub-font);
    margin-bottom: 1rem;
}

.footer_info_social-icons {
    display: flex;
}
.footer_info_social-icons a {
 font-size: 1.5rem;
 margin-right: 1rem;
 transition: all 0.3s;
 -webkit-transition: all 0.3s;
 -moz-transition: all 0.3s;
 -ms-transition: all 0.3s;
 -o-transition: all 0.3s;
}
.footer_info_social-icons a:hover{
    color: var(--primary-color);
}



.footer_info_content-2 h4,
.footer_info_content-3 h4,
.footer_info_content-4 h4{
    font-size: 22px;
    font-weight: 500;
    line-height: 27px;

    margin: 1rem 0 1.5rem 0;
}

.footer_info_content-2 > span,
.footer_info_content-3 > span,
.footer_info_content-4 > span{
    font-size: 1rem;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: .4rem;
}


.footer_info_content-2 > span > a,
.footer_info_content-3 > span > a,
.footer_info_content-4 > span > a{
    transition: 0.5s ease-in-out;
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -ms-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
}
.footer_info_content-2 > span > a:hover,
.footer_info_content-3 > span > a:hover,
.footer_info_content-4 > span > a:hover{
    color: var(--text-black);
}


.footer_info_content-4 span form{
    display: flex;
    justify-content: space-between;
    
    width: fit-content;
    margin-top: .5rem;
    border-radius: .5rem;
    background-color:var(--text-white);
    overflow: hidden;
    height: fit-content;

   
}
.footer_info_content-4 span form input{
    flex: .65;
    color: var(--text-black);
    border: 0;
    outline: 0;
    
    font-size: 14px;
    font-family: var(--sub-font);
    letter-spacing: 1px;
    padding: .8rem 1rem;
    background: transparent;

    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
}

.footer_info_content-4 span form input:focus{
    border: 0;
    outline: 0;  
}

.footer_info_content-4 span form button{
    flex: .35;
    border: 0;
    background-color: var(--primary-color);
    cursor: pointer;
    font-size: 16px;
    padding: 0 1rem;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

.footer_info_content-4 span form button:hover{
    background-color: var(--secondary-color);
    color: var(--text-white);
}

/* footer Copy Right section  */
.footer_copyRight{
    color: var(--text-white);
    font-size: 15px;
    font-weight: 300;
    text-align: center;
    margin-top: 3rem;
    padding-bottom: 2rem;
}

.footer_copyRight hr{
    width: 60%;
    margin: .5rem auto;
}

.footer_copyRight >span a{
    color: var(--text-black);
    text-decoration: revert;
}

.footer_copyRight >span a:hover{
    color: var(--text-white);
}
.footer_copyRight >span a:nth-child(1){
  margin-right: 1rem;
}

/* ===========Responive============= */

@media only screen and (max-width:1920px) and (min-width:1301px){
    .footer_info_container{
        padding: 0;
        max-width: 80%;
    }
}
@media only screen and (max-width:1025px) and (min-width:481px){
    .footer_info_container{
        padding: 0 2rem;
    }
    footer{
        padding-top: 1rem;
    }
    .footer_info_content-1 > p{
        width: 100%;
    }

    .footer_info_social p{
        text-align: start;
    }

    
    .footer_info_content-4 span form input {
        padding: .5rem;
        width: 100%;
        min-width: 173px;
    }
    .footer_info_content-4 span form button{
        padding: .5rem;
    }

    .footer_copyRight hr{
        width: 100%;
    }

    .footer_info_container{
        grid-template-columns: repeat(2,1fr);gap: 1rem;
    }
}

@media only screen and (max-width:481px){
    footer{
        padding-top: 1rem;
    }
    .footer_info_container{
        width: 100%;
        padding:0 1rem 2rem;
    }
    .footer_info_content-1 {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 5;
    }
    .footer_info_content-2 {
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 3;
    }
    .footer_info_content-3 {
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start: 3;
        grid-column-end: 5;
    }
    .footer_info_content-4 {
        grid-row-start: 3;
        grid-row-end: 4;
        grid-column-start: 1;
        grid-column-end: 5;
    }


    .footer_info_content-1 > p{
        width: 100%;
    }

    .footer_info_social p{
        text-align: start;
    }


    .footer_info_content-4 span{
       justify-content: center;
       align-items: flex-start;
    }
    .footer_info_content-4 span form{
        align-self: center;
    }

    .footer_info_content-4 span form button{
        padding: .85rem 1rem;
    }

    .footer_copyRight hr{
        width: 100%;
    }
    .footer_copyRight{
        margin-top: 0;

    }

    .footer_info_content-1 img{
        width: 50%;
    }

    .footer_info_content-2 h4, .footer_info_content-3 h4, .footer_info_content-4 h4{
        font-size: 20px;
        margin-bottom: .5rem;
    }
}
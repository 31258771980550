.cardTwo_img{
    flex: .5;
    border-radius:.7rem ;
    position: relative;
}

.cardTwo_img img{
    z-index: 99;
    position: relative;
}
.cardTwo_img_layer{
    width: 299px;
    height: 299px;
    position: absolute;
    z-index: 0;
}

@media only screen and (max-width:1600px) and (min-width:769px) {
    .cardTwo_img{
        width: 750px;
        max-width: 100%;
    }
    
}

@media only screen and (max-width:769px){
    .cardTwo_img_layer{
        display: none;
    }
    
}

.offer-item{
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 0 10px 40px 0;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
}
.offer-item:hover{
    box-shadow: 0 2px 15px 0 rgba(14,122,133,.5);
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}
.offer-item .offer-icon{
    margin-right: 20px;
}
.offer-item .offer-icon img{
    width: 100%;
    height: 50px;

}
.offer-item .offer-text p{
    font-size: 14px;
    font-weight: 700;
    color: #262626;
}
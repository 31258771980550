.SubmitDone_card_container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    gap: 4rem;

    width: 80%;
    max-width: 2200px;
    margin: 4rem auto 3rem auto;
}

.SubmitDone_card{
    flex: .5;
   
}

.SubmitDone_card-content{
   width: 493px;
   height: 321px;
   padding: 7%;
   margin-left: auto;
   border-radius: .3rem;
   box-shadow: 0 0 7px 1px rgba(133, 133, 133, 0.4);
   
   background: url(../../../public/assets/submitdone_cardbg.png)no-repeat;
   background-position: bottom right;

   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: flex-start;
}
.SubmitDone_card-content h3{
    font-size: 2rem;
    font-weight: 500;
    line-height: 39.01px;
}
.SubmitDone_card-content p{
    font-size: 16px;
    line-height: 24px;
}
.SubmitDone_card-content a{
    color: var(--primary-color);
    font-weight: 500;
    background: #F2F2F2;
    border-radius: .4rem;
    padding: .5rem 1rem;
}


.SubmitDone_card-image{
    flex: .5;
}


@media screen and (max-width:1400px)and (min-width:769px){
    .SubmitDone_card_container{
        width: 100%;
        padding: 3rem;
        justify-content: center;
    }
}



@media screen and (max-width:769px) {
    .SubmitDone_card_container{
        width: 100%;
        padding: 0 2rem;

        align-items: center;
    }

    .SubmitDone_card-content{
    width: 100%;
    }
    .SubmitDone_card,.SubmitDone_card-image{
        flex: auto;
    }

    .SubmitDone_card-image{
        width: 100%;
    }

    .SubmitDone_card-image img{
        width: 100%;
        height: 100%;
    }

}